import { gql, useApolloClient } from '@apollo/client'
import { BatchImportListings, useAPIClient } from '@propps/client'
import {
  Button,
  CommonError,
  FixedButtonWrapper,
  ListItem,
  ListWrapper,
  Select,
} from '@propps/ui'
import Papa from 'papaparse'
import { difference } from 'ramda'
import React, { useEffect, useState } from 'react'

import { AgencyImportListings_Agency } from './__generated__/AgencyImportListings_Agency'

export function AgencyImportListings({
  agency,
  onSuccess,
}: {
  agency: AgencyImportListings_Agency | null
  onSuccess?: () => void
}) {
  const client = useAPIClient()
  const apollo = useApolloClient()
  const [isPending, setPending] = useState(false)
  const [appId, setAppId] = useState<string | null>(null)
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [output, setOuput] = useState<string | null>(null)

  useEffect(() => {
    if (agency && !appId && agency.connectedDeveloperApps.length) {
      setAppId(agency.connectedDeveloperApps[0].id)
    }
  }, [agency, appId])

  const handleFilesUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0] || null)
  }

  const process = async () => {
    setPending(true)
    try {
      setError(null)
      if (!file) return
      if (!agency) return
      if (!appId) return

      const [headers]: string[][] = await new Promise((resolve) =>
        Papa.parse(file, {
          preview: 1,
          transform: (value) => value.toLowerCase(),
          complete: ({ data }) => resolve(data as string[][]),
        })
      )

      const missingHeaders = difference(HEADERS, headers)
      if (missingHeaders.length) {
        setError(
          'Incorrect format. The following columns were not found: ' +
            missingHeaders.join(', ') +
            '.'
        )
        return
      }

      const contents = await new Promise<string>((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', (event) => {
          resolve(event.target!.result as string)
        })
        reader.readAsText(file)
      })

      try {
        const result = await client.request(BatchImportListings, {
          input: { appId, csv: contents },
        })
        setOuput(
          result.listings
            .map(
              (listing) =>
                `${listing.source.foreignId} ${listing.id} ${listing.property.address.line1}`
            )
            .join('\n')
        )
        apollo.cache.modify({
          fields: {
            listings: (value, { INVALIDATE }) => INVALIDATE,
          },
        })
        apollo.cache.modify({
          id: apollo.cache.identify({ __typename: 'Agency', id: agency.id }),
          fields: {
            listings: (value, { INVALIDATE }) => INVALIDATE,
          },
        })

        onSuccess && onSuccess()
      } catch (err) {
        setOuput(JSON.stringify(err.body || err.message, null, 2))
      }
    } finally {
      setPending(false)
    }
  }

  return (
    <form>
      <ListWrapper>
        <ListItem>
          <label>Developer app</label>
          <Select
            value={appId || ''}
            onChange={(e) => setAppId(e.target.value)}
          >
            <option value="" disabled></option>
            {agency?.connectedDeveloperApps.map((app) => (
              <option key={app.id} value={app.id}>
                {app.name}
              </option>
            ))}
          </Select>
        </ListItem>
      </ListWrapper>
      <input type="file" onChange={handleFilesUpdate} accept=".csv" />

      <div>{error && <CommonError>{error}</CommonError>}</div>
      {output && <pre>{output}</pre>}
      <FixedButtonWrapper>
        <Button onClick={process} cta pending={isPending}>
          Process
        </Button>
      </FixedButtonWrapper>
    </form>
  )
}

AgencyImportListings.fragments = {
  Agency: gql`
    fragment AgencyImportListings_Agency on Agency {
      id
      name
      connectedDeveloperApps {
        id
        name
      }
    }
  `,
}

const HEADERS = [
  'id',
  'url',
  'addr1',
  'city',
  'state',
  'postcode',
  'a1email',
  'a1name',
  'a1phone',
  'a2email',
  'a2name',
  'a2phone',
  'price_min',
  'price_max',
  'price_message',
]
