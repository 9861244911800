import {
  gql,
  TypedDocumentNode,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client'
import { ErrorReporting, useAuth, useSearchParams } from '@propps/client'
import { Button, FixedButtonWrapper } from '@propps/ui'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { ErrorPage } from '../../../components/error-page'
import { AuthHistoryState } from '../../auth'
import {
  AgentClaimInvitationMutation,
  AgentClaimInvitationMutationVariables,
} from './__generated__/AgentClaimInvitationMutation'
import {
  AgentClaimInvitationQuery,
  AgentClaimInvitationQueryVariables,
} from './__generated__/AgentClaimInvitationQuery'
import { AgentRegisterUserQuery } from './__generated__/AgentRegisterUserQuery'

export function AgentRegister({
  onSuccess,
}: {
  onSuccess: (() => void) | (() => Promise<void>)
}) {
  const params = useSearchParams<{ invitation_key?: string }>()
  const auth = useAuth()
  const apollo = useApolloClient()
  const history = useHistory<AuthHistoryState>()
  const [isPending, setPending] = useState(false)

  const { data, error } = useQuery(AGENT_CLAIM_INVITATION_QUERY, {
    variables: { key: params.invitation_key },
    skip: !params.invitation_key,
    fetchPolicy: 'network-only',
  })

  const { data: data2, error: error2 } = useQuery(AGENT_REGISTER_USER_QUERY, {
    fetchPolicy: 'network-only',
  })

  const [mutate] = useMutation(AGENT_CLAIM_INVITATION_MUTATION, {
    variables: { key: params.invitation_key },
  })

  const uid = auth.uid
  const claimInvitation = useCallback(async () => {
    if (isPending) return
    setPending(true)

    try {
      // claim the invitation
      const { data: data3, errors } = await mutate()

      if (errors) {
        ErrorReporting.report(errors)
        return
      }

      // activate the newly added agent role
      await auth.signInWithToken(data3!.result.token)

      apollo.cache.modify({
        id: apollo.cache.identify({
          __typename: 'User',
          uid: uid,
        }),
        fields: {
          // the user should now have the agent role
          roles: (value, { INVALIDATE }) => INVALIDATE,
          // the user should now be linked to the agent
          agent: (value, { INVALIDATE }) => INVALIDATE,
        },
      })

      await onSuccess()
    } finally {
      setPending(false)
    }
  }, [isPending, mutate, auth, apollo.cache, uid, onSuccess])

  // if the user is not signed in and the invitation is valid, ask them to sign in.
  useEffect(() => {
    if (
      data &&
      data2 &&
      !data2.me &&
      data.invitation &&
      data.invitation.valid
    ) {
      // send the user to the sign in page with the agent registration intent
      // they will be sent back after signing in
      history.replace('/auth/sign-in', {
        intent: {
          type: 'register-agent',
          agent: data.invitation.agent,
          redirect: history.location.pathname + history.location.search,
        },
      })
    }
  }, [auth.role, auth.uid, data, data2, history])

  // if the user is signed in and has no roles, immediately claim the invitation
  useEffect(() => {
    if (
      data &&
      data2 &&
      data2.me &&
      !data2.me.roles.length &&
      data.invitation &&
      data.invitation.valid &&
      !isPending
    ) {
      claimInvitation()
    }
  }, [auth.role, auth.uid, claimInvitation, data, data2, isPending])

  useEffect(() => {
    if (
      data &&
      data2 &&
      data2?.me?.agent &&
      data.invitation &&
      data2.me.agent.id === data.invitation.agent.id
    ) {
      history.replace('/agent/onboarding')
    }
  }, [data, data2, history])

  if (error || error2) {
    // there was an error loading either the invitation or user data
    throw error || error2
  }

  if (data2 === undefined) {
    // loading user data
    return (
      <FixedButtonWrapper>
        <Button cta pending />
      </FixedButtonWrapper>
    )
  }

  if (data2.me?.agent) {
    // the user managed to get to the registration page even though they are already an agent
    return (
      <p>
        You are already registered as an agent.
        <br />
        <Link to="/agent">Home</Link>
      </p>
    )
  }

  if (!params.invitation_key) {
    // the user somehow managed to get to this page without an invitation key
    return (
      <ErrorPage
        title={
          <Fragment>
            Oops
            <br />
            <span className="light">Are you meant to be here?</span>
          </Fragment>
        }
        errorMessage={
          <Fragment>
            To view this page you need to click an invitation link, but it seems
            like the one you clicked is broken. If you're still having trouble,
            contact support@propps.com.
          </Fragment>
        }
        showHomeButton
      />
    )
  }

  if (data === undefined) {
    // loading invitation data
    return (
      <FixedButtonWrapper>
        <Button cta pending />
      </FixedButtonWrapper>
    )
  }

  if (!data.invitation?.valid) {
    // invitation is invalid (wrong invitation key, expired, or already claimed)
    return (
      <ErrorPage
        title={
          <Fragment>
            Oh, bugger
            <br />
            <span className="light">Something's wrong.</span>
          </Fragment>
        }
        errorMessage={
          <Fragment>
            It seems like your invitation link is invalid or expired. You may
            want to ask for a new one from your admin or agency. If you're still
            having trouble, contact support@propps.com.
          </Fragment>
        }
      />
    )
  }

  if (data2.me?.roles.length) {
    return (
      <p>
        Looks like you aleady have an account with Propps.
        <br />
        Would you like to register as the Agent{' '}
        {data.invitation.agent.firstName} {data.invitation.agent.lastName}?
        You'll still keep access to your other role accounts.
        <br />
        <Button onClick={() => claimInvitation()}>Register as agent</Button>
      </p>
    )
  }

  if (isPending) {
    // the logged in user is being linked with the agent
    return (
      <FixedButtonWrapper>
        <Button cta pending />
      </FixedButtonWrapper>
    )
  }

  // this should only show for a split second before redirecting the user to sign in.
  // maybe make this an empty screen?
  return (
    <FixedButtonWrapper>
      <Button cta pending />
    </FixedButtonWrapper>
  )
}

const AGENT_REGISTER_USER_QUERY: TypedDocumentNode<AgentRegisterUserQuery> = gql`
  query AgentRegisterUserQuery {
    me {
      roles
      agent {
        id
      }
    }
  }
`

const AGENT_CLAIM_INVITATION_QUERY: TypedDocumentNode<
  AgentClaimInvitationQuery,
  AgentClaimInvitationQueryVariables
> = gql`
  query AgentClaimInvitationQuery($key: ID!) {
    invitation: agentInvitation(key: $key) {
      id
      valid
      agent {
        id
        firstName
        lastName
      }
    }
  }
`

const AGENT_CLAIM_INVITATION_MUTATION: TypedDocumentNode<
  AgentClaimInvitationMutation,
  AgentClaimInvitationMutationVariables
> = gql`
  mutation AgentClaimInvitationMutation($key: ID!) {
    result: claimAgentInvitation(input: { invitationKey: $key }) {
      agent {
        user {
          uid
        }
      }
      token
    }
  }
`
