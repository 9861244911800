import { StackMain } from '@propps/ui'
import React from 'react'
import {
  match as Match,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'

import { AgentOnboardingComplete } from './onboarding/complete'
import { AgentOnboardingTerms } from './onboarding/terms'
import { AgentRegister } from './register'

export function AgentRoot({ match }: { match: Match }) {
  const history = useHistory()

  return (
    <Switch>
      <Route path={`${match.path}/register`}>
        {() => (
          <StackMain variant="topCenter">
            <AgentRegister
              onSuccess={() => {
                history.push(`${match.url}/onboarding`)
              }}
            />
          </StackMain>
        )}
      </Route>
      <Route path={`${match.path}/onboarding`} exact>
        {() => <Redirect to={`${match.url}/onboarding/terms`} />}
      </Route>
      <Route path={`${match.path}/onboarding/terms`}>
        {() => (
          <AgentOnboardingTerms
            onSuccess={() => history.push(`${match.url}/onboarding/complete`)}
          />
        )}
      </Route>
      <Route path={`${match.path}/onboarding/complete`}>
        {() => <AgentOnboardingComplete />}
      </Route>
    </Switch>
  )
}
