import React from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'
import { TermsLineRoot } from './line'

export function TermsRoot({ match }: { match: Match }) {
  return (
    <Switch>
      <Route path={`${match.path}/:lineName`}>
        {({ match }) => <TermsLineRoot match={match!} />}
      </Route>
    </Switch>
  )
}
