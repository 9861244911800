import { gql, TypedDocumentNode, useMutation } from '@apollo/client'
import { ErrorReporting, getErrors } from '@propps/client'
import {
  Button,
  FixedButtonWrapper,
  Input,
  ListItem,
  ListWrapper,
  SectionHeader,
  StackMain,
} from '@propps/ui'
import { useFormik } from 'formik'
import React from 'react'
import { match as Match, useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import {
  CreateAgencyMutation,
  CreateAgencyMutationVariables,
} from './__generated__/CreateAgencyMutation'

const CREATE_AGENCY_MUTATION: TypedDocumentNode<
  CreateAgencyMutation,
  CreateAgencyMutationVariables
> = gql`
  mutation CreateAgencyMutation($input: CreateAgencyInput!) {
    result: createAgency(input: $input) {
      agency {
        id
        name
      }
    }
  }
`

export function CreateAgency({ match }: { match: Match }) {
  const history = useHistory()
  const [mutate] = useMutation(CREATE_AGENCY_MUTATION, {
    update: (cache, { data }) => {
      if (data) {
        cache.modify({
          fields: {
            agencies: (value) => {
              return [
                ...value,
                cache.writeFragment({
                  data: data.result.agency,
                  fragment: gql`
                    fragment CreateAgencyFragment on Agency {
                      id
                      name
                    }
                  `,
                }),
              ]
            },
          },
        })
      }
    },
  })

  const formik = useFormik({
    initialValues: { name: '', region: '' },
    validationSchema: ValidationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const { data, errors } = await mutate({ variables: { input: values } })

        if (!data) {
          ErrorReporting.report(errors)
          return
        }

        history.push(match.url.replace('/create', '/' + data.result.agency.id))
      } catch (err) {
        ErrorReporting.report(err)
      }
    },
  })

  return (
    <StackMain variant="app">
      <form onSubmit={formik.handleSubmit}>
        <h1>Create an Agency</h1>
        <SectionHeader hr h3="Details" />
        <ListWrapper>
          <ListItem>
            <label>Name</label>
            <Input
              type="text"
              {...formik.getFieldProps('name')}
              errors={getErrors(formik, 'name')}
            />
          </ListItem>
          <ListItem>
            <label>Region</label>
            <Input
              type="text"
              {...formik.getFieldProps('region')}
              errors={getErrors(formik, 'region')}
            />
          </ListItem>
        </ListWrapper>
        <FixedButtonWrapper>
          <Button type="submit" cta pending={formik.isSubmitting}>
            Create
          </Button>
        </FixedButtonWrapper>
      </form>
    </StackMain>
  )
}

const ValidationSchema = Yup.object({
  name: Yup.string().required().label('Name'),
  region: Yup.string().required().label('Region'),
})
