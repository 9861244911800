/** @jsx jsx */
import { gql, TypedDocumentNode, useMutation, useQuery } from '@apollo/client'
import { jsx } from '@emotion/react'
import { getErrors } from '@propps/client'
import {
  AppCard,
  Button,
  Cards,
  Input,
  Label,
  ListItem,
  ListWrapper,
  Plus,
  Section,
  SectionHeader,
  Select,
  StackModal,
  UserCard,
  useStackModalState,
} from '@propps/ui'
import { useFormik } from 'formik'
import { Fragment } from 'react'
import { match as Match } from 'react-router-dom'
import * as Yup from 'yup'

import { useToast } from '../../../components/toast'
import { AgencySettings_Agency } from './__generated__/AgencySettings_Agency'
import {
  ConnectDeveloperApp,
  ConnectDeveloperAppVariables,
} from './__generated__/ConnectDeveloperApp'
import { ListDeveloperApps } from './__generated__/ListDeveloperApps'
import {
  UpdateAgencySettings,
  UpdateAgencySettingsVariables,
} from './__generated__/UpdateAgencySettings'

export function AgencySettings({
  match,
  agency,
}: {
  match: Match
  agency: AgencySettings_Agency
}) {
  const {
    addInfoNotification,
    addSuccessNotification,
    addErrorNotification,
  } = useToast()
  const [update] = useMutation<
    UpdateAgencySettings,
    UpdateAgencySettingsVariables
  >(UPDATE_AGENCY_SETTINGS_MUTATION)

  const connectDeveloperAppModal = useStackModalState()

  const formik = useFormik({
    initialValues: {
      agency: agency,
    },
    onSubmit: async (values) => {
      const progressToastId = addInfoNotification({
        label: 'Settings updating...',
        progress: true,
      })
      try {
        const fetchResult = await update({
          variables: {
            input: {
              id: agency?.id,
              name: formik.values.agency?.name,
              region: formik.values.agency?.region,
            },
          },
        })
        if (fetchResult.errors) {
          addErrorNotification({
            label: 'Settings update error',
            description: fetchResult.errors[0].message,
            updateToastId: progressToastId,
          })
          return
        }
        addSuccessNotification({
          label: 'Settings updated',
          description: 'Settings updated successfully',
          updateToastId: progressToastId,
        })
      } catch (e) {
        console.log(e)
        addErrorNotification({
          label: 'Settings update error',
          description: e.message,
          updateToastId: progressToastId,
        })
        return
      }
    },
  })

  if (!agency) {
    return null
  }

  return (
    <Fragment>
      <Section>
        <SectionHeader hr h3="Details" />
        <form onSubmit={formik.handleSubmit}>
          <ListWrapper>
            <ListItem>
              <Label size="small" color="grey">
                Name
              </Label>
              <Input
                type="text"
                placeholder="..."
                {...formik.getFieldProps(`agency.name`)}
                errors={getErrors(formik, `agency.name`)}
              />
            </ListItem>
            <ListItem>
              <Label size="small" color="grey">
                Region
              </Label>
              <Input
                type="text"
                placeholder="..."
                {...formik.getFieldProps(`agency.region`)}
                errors={getErrors(formik, `agency.region`)}
              />
            </ListItem>
          </ListWrapper>
          <Button type="submit">Save changes</Button>
        </form>
      </Section>
      <Section>
        <SectionHeader hr2 h3="Linked developer apps" />
        <Cards grid>
          {agency?.connectedDeveloperApps.map((item) => (
            <AppCard
              key={item.id}
              id={item.id}
              title={item.name}
              status="Enabled"
            />
          ))}
          <UserCard
            variant="dashed"
            svg={Plus}
            primaryText="Link developer app"
            secondaryText="To view or manage offers"
            onClick={() => connectDeveloperAppModal.show()}
          />
        </Cards>
      </Section>
      <StackModal state={connectDeveloperAppModal}>
        <ConnectDeveloperAppForm
          agencyId={agency.id}
          onSuccess={() => connectDeveloperAppModal.hide()}
        />
      </StackModal>
    </Fragment>
  )
}

AgencySettings.fragments = {
  Agency: gql`
    fragment AgencySettings_Agency on Agency {
      id
      name
      region
      connectedDeveloperApps {
        id
        name
      }
    }
  `,
}

function ConnectDeveloperAppForm({
  agencyId,
  onSuccess,
}: {
  agencyId: string
  onSuccess?: () => void
}) {
  const { data } = useQuery(LIST_DEVELOPER_APPS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })
  const [mutate] = useMutation(CONNECT_DEVELOPER_APP_MUTATION, {})

  const formik = useFormik({
    initialValues: { developerAppId: '' },
    validationSchema: ValidationSchema,
    onSubmit: async (values, helpers) => {
      await mutate({
        variables: {
          input: { agencyId, developerAppId: values.developerAppId },
        },
      })
      onSuccess && onSuccess()
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <ListWrapper>
        <ListItem>
          <label>Developer app</label>
          <Select
            {...formik.getFieldProps('developerAppId')}
            errors={getErrors(formik, 'developerAppId')}
          >
            <option value=""></option>
            {data?.developerApps.map((app) => (
              <option key={app.id} value={app.id}>
                {app.name}
              </option>
            ))}
          </Select>
        </ListItem>
      </ListWrapper>
      <Button type="submit" disabled={formik.isSubmitting}>
        {formik.isSubmitting ? 'Pending...' : 'Link developer app'}
      </Button>
    </form>
  )
}

const LIST_DEVELOPER_APPS_QUERY: TypedDocumentNode<ListDeveloperApps> = gql`
  query ListDeveloperApps {
    developerApps {
      id
      name
    }
  }
`
const CONNECT_DEVELOPER_APP_MUTATION: TypedDocumentNode<
  ConnectDeveloperApp,
  ConnectDeveloperAppVariables
> = gql`
  mutation ConnectDeveloperApp($input: ConnectDeveloperAppToAgencyInput!) {
    result: connectDeveloperAppToAgency(input: $input) {
      agency {
        id
        connectedDeveloperApps {
          id
        }
      }
    }
  }
`

const UPDATE_AGENCY_SETTINGS_MUTATION = gql`
  mutation UpdateAgencySettings($input: UpdateAgencyInput!) {
    result: updateAgency(input: $input) {
      agency {
        id
        name
        region
      }
    }
  }
`

const ValidationSchema = Yup.object({
  developerAppId: Yup.string().required().label('Developer app'),
})
