import React from 'react'
import { match as Match, Redirect, Route, Switch } from 'react-router-dom'
import { AuthGuardRedirect, Role, RoleRecord } from '@propps/client'

import { PlatformHint } from './platformHint'
import { PlatformUser } from './platformUser'

export function PlatformAuthRoot({
  to,
  match,
  allow,
}: {
  to: Record<Role, string>
  match: Match
  allow: (auth: { uid: string | null; role: RoleRecord | null }) => boolean
}) {
  return (
    <Switch>
      <Route path={match.url} exact>
        <Redirect to={match.url + '/sign-in'} />
      </Route>
      <Route path={match.url + '/sign-up'}>
        {({ match }) => (
          <AuthGuardRedirect allow={allow} to="/auth">
            <PlatformHint to={to} />
          </AuthGuardRedirect>
        )}
      </Route>
      <Route path={match.url + '/sign-in'}>
        {({ match }) => <PlatformUser to={to} />}
      </Route>
    </Switch>
  )
}
