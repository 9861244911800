import { gql, TypedDocumentNode, useMutation, useQuery } from '@apollo/client'
import { firebase } from '@propps/client'
import {
  ArrowOutUpRight,
  FileSingle,
  Flex,
  Icon,
  Label,
  ListItem,
  ListWrapper,
  Section,
  SectionHeader,
  UploadFile,
} from '@propps/ui'
import React, { ChangeEvent } from 'react'

import { useToast } from '../../../../components/toast'
import { ListingDocsQuery } from './__generated__/ListingDocsQuery'
import { ListingDocs_Listing } from './__generated__/ListingDocs_Listing'
import {
  UploadListingContractMutation,
  UploadListingContractMutationVariables,
} from './__generated__/UploadListingContractMutation'

export function ListingDocs({ listing }: { listing: ListingDocs_Listing }) {
  const [updateContract] = useMutation(UPLOAD_LISTING_CONTRACT)

  const { data } = useQuery(LISTING_DOCS_QUERY)
  const {
    addInfoNotification,
    addSuccessNotification,
    addErrorNotification,
  } = useToast()

  const handleCosUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!data?.me) {
      return
    }

    const fileName =
      e.target.files && e.target.files.length === 1
        ? e.target.files[0].name
        : ''

    const storage = firebase.storage()
    const ref = storage.ref('/uploads/' + data.me.uid + '/' + fileName)

    const progressToastId = addInfoNotification({
      label: 'File uploading...',
      description: fileName,
      progress: true,
    })
    if (e.target.files && e.target.files.length === 1) {
      try {
        await ref.put(e.target.files[0])
        await updateContract({
          variables: {
            id: listing.id,
            file: { bucket: ref.bucket, path: ref.fullPath },
          },
        })

        addSuccessNotification({
          label: 'File uploaded',
          description: fileName,
          updateToastId: progressToastId,
        })
      } catch (err) {
        console.log(err)
        addErrorNotification({
          label: 'File upload error',
          description: JSON.stringify(err.body || err.message, null, 2),
          updateToastId: progressToastId,
        })
      }
    } else {
      addErrorNotification({
        label: 'File upload error',
        description: 'Multiple files upload is not supported',
        updateToastId: progressToastId,
      })
    }
  }

  return (
    <Section>
      <SectionHeader hr h3="Contract of sale" />
      <ListWrapper>
        {listing.documents
          ?.filter((value) => value?.name?.toLowerCase() === 'cos')
          .map((item, index) => {
            return (
              <ListItem
                row
                clickable
                onClick={() => window.open(item?.url ?? '', '_blank')}
                key={'cos-item-' + index}
              >
                <Flex xs={{ alignItems: 'flex-start' }}>
                  <Icon svg={FileSingle} />
                  <Label icon color="black" cursor="pointer">
                    Contract of sale.pdf
                  </Label>
                </Flex>
                <Icon svg={ArrowOutUpRight} />
              </ListItem>
            )
          })}
      </ListWrapper>
      <UploadFile tabIndex={0} onChange={handleCosUpload} />
    </Section>
  )
}

ListingDocs.fragments = {
  Listing: gql`
    fragment ListingDocs_Listing on Listing {
      id
      source {
        appId
        foreignId
      }
      documents {
        name
        url
      }
    }
  `,
}

export const UPLOAD_LISTING_CONTRACT: TypedDocumentNode<
  UploadListingContractMutation,
  UploadListingContractMutationVariables
> = gql`
  mutation UploadListingContractMutation($id: ID!, $file: FileUploadInput!) {
    result: updateListingContract(input: { id: $id, contract: $file }) {
      listing {
        ...ListingDocs_Listing
      }
    }
  }
  ${ListingDocs.fragments.Listing}
`

export const LISTING_DOCS_QUERY: TypedDocumentNode<ListingDocsQuery> = gql`
  query ListingDocsQuery {
    me {
      uid
    }
  }
`
