import { useAPIClient } from '@propps/client'
import { Button, Section, SectionHeader, StackMain } from '@propps/ui'
import React, { Fragment, useState } from 'react'
import { match as Match } from 'react-router-dom'

export function Settings({ match }: { match: Match }) {
  const client = useAPIClient()
  const [isPending, setPending] = useState(false)

  const handleResetAccounts = async () => {
    if (isPending) return
    setPending(true)

    try {
      await client.request(
        {
          service: 'Core-UnlinkBuyers',
          path: '/',
          method: 'POST',
        },
        {
          input: {
            phoneNumbers: [
              '+61403228068', // tills
              '+61449228400', // david
              '+61407796006', // bignold
            ],
          },
        }
      )
      setPending(false)
    } finally {
      setPending(false)
    }
  }

  return (
    <Fragment>
      <StackMain variant="app">
        <h1>Settings</h1>
        <Section>
          <SectionHeader hr2 h3="Super secret stuff" />
          <Button danger onClick={handleResetAccounts}>
            {isPending ? 'Pending...' : 'Reset demo accounts'}
          </Button>
          <p>
            Clicking this button will unlink the buyer accounts and any related
            offers from Dan, Dave and Matilda's phone numbers.
          </p>
        </Section>
      </StackMain>
    </Fragment>
  )
}
