import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import React from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'

import { AgencyView } from './agency'
import { GetAgency, GetAgencyVariables } from './__generated__/GetAgency'

const GET_AGENCY_QUERY: TypedDocumentNode<GetAgency, GetAgencyVariables> = gql`
  query GetAgency($id: ID!) {
    agency(id: $id) {
      id
      name
      region
      ...AgencyView_Agency
    }
  }
  ${AgencyView.fragments.Agency}
`

export function AgencyRoot({ match }: { match: Match<{ agencyId: string }> }) {
  const result = useQuery(GET_AGENCY_QUERY, {
    variables: { id: match.params.agencyId },
    fetchPolicy: 'cache-and-network',
  })

  const agency = result.data?.agency
  if (!agency) {
    return null
  }

  return (
    <Switch>
      <Route path={match.path}>
        {({ match }) => <AgencyView agency={agency} match={match!} />}
      </Route>
    </Switch>
  )
}
