/** @jsx jsx */
import { useMutation, useQuery } from '@apollo/client'
import { gql, TypedDocumentNode } from '@apollo/client/core'
import { css, jsx } from '@emotion/react'
import {
  Button,
  Checkbox,
  CheckboxGroup,
  color,
  HeroSmile,
  Icon,
  size,
  StackMain,
} from '@propps/ui'
import { useFormik } from 'formik'
import { Fragment, useEffect } from 'react'
import * as Yup from 'yup'

import {
  AgentOnboardingTermsQuery,
  AgentOnboardingTermsQueryVariables,
} from './__generated__/AgentOnboardingTermsQuery'
import {
  UpdateAgencyTermsAndConditionsMutation,
  UpdateAgencyTermsAndConditionsMutationVariables,
} from './__generated__/UpdateAgencyTermsAndConditionsMutation'
import {
  UpdateAgentTermsAndConditionsMutation,
  UpdateAgentTermsAndConditionsMutationVariables,
} from './__generated__/UpdateAgentTermsAndConditionsMutation'

const mv4 = css`
  margin: ${size(4)} 0;
`

const mt2 = css`
  margin-top: ${size(2)};
`

const colorRed = css`
  color: ${color.red};
`

const colorGrey = css`
  color: ${color.grey};
`

export const AgentOnboardingTerms = ({
  onSuccess,
}: {
  onSuccess: (() => void) | (() => Promise<void>)
}) => {
  const { data } = useQuery(AGENT_ONBOARDING_TERMS_QUERY, {
    variables: {
      agentTermsLine: 'au-agent',
      privacyPolicyLine: 'au-privacy-policy',
    },
  })

  useEffect(() => {
    // If all terms are already accepted, then just continue to the next page
    if (
      data &&
      data.me?.agent?.agency?.termsAndConditions?.accepted &&
      data.me?.agent?.termsAndConditions?.accepted
    ) {
      onSuccess()
    }
  }, [data, onSuccess])

  const [updateAgentTerms] = useMutation(
    UPDATE_AGENT_TERMS_AND_CONDITIONS_MUTATION
  )
  const [updateAgencyTerms] = useMutation(
    UPDATE_AGENCY_TERMS_AND_CONDITIONS_MUTATION
  )

  const formik = useFormik<{
    terms: boolean
    privacyPolicy: boolean
  }>({
    initialValues: {
      terms: false,
      privacyPolicy: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      if (!(data?.me?.agent?.agency && data.agentTerms && data.privacyPolicy))
        return

      if (!data.me.agent.agency.termsAndConditions?.accepted) {
        await updateAgencyTerms({
          variables: {
            input: {
              id: data.me.agent.agency.id,
              revisionNo: 'au-agent',
              accepted: true,
            },
          },
        })
      }

      await updateAgentTerms({
        variables: {
          input: {
            id: data.me.agent.id,
            revisionNo: 'au-agent',
            accepted: true,
          },
        },
      })

      onSuccess()
    },
  })

  if (!data) {
    return null
  }

  return (
    <StackMain variant="topCenter">
      <form
        onSubmit={formik.handleSubmit}
        css={css`
          width: 100%;
        `}
      >
        <Icon svg={HeroSmile} size={128} />
        <h1 css={mv4}>
          Welcome to Propps <br />{' '}
          <span className="light">{data?.me?.agent?.agency?.name}</span>
        </h1>
        <p css={colorGrey}>
          It’s great to have you on board. We’re looking forward to making
          offers a seamless, integrated experience.
        </p>
        <CheckboxGroup>
          <Checkbox
            label={
              <Fragment>
                I agree to the{' '}
                <a href="/terms/au-agent" target="blank">
                  Propps Terms &amp; Conditions
                </a>
              </Fragment>
            }
            {...formik.getFieldProps({
              type: 'checkbox',
              name: 'terms',
            })}
          />
          <Checkbox
            label={
              <Fragment>
                I agree to the{' '}
                <a href="/terms/au-privacy-policy" target="blank">
                  Propps Privacy Policy
                </a>
              </Fragment>
            }
            {...formik.getFieldProps({
              type: 'checkbox',
              name: 'privacyPolicy',
            })}
          />
        </CheckboxGroup>
        {((formik.touched.privacyPolicy && formik.errors.privacyPolicy) ||
          (formik.touched.terms && formik.errors.terms)) && (
          <p css={colorRed}>
            You’ll need to agree to the Propps Terms & Conditions and Propps
            Privacy Policy.
          </p>
        )}
        <Button
          cta
          type="submit"
          disabled={formik.isSubmitting}
          css={mt2}
          pending={formik.isSubmitting}
        >
          Continue
        </Button>
      </form>
    </StackMain>
  )
}

const UPDATE_AGENT_TERMS_AND_CONDITIONS_MUTATION: TypedDocumentNode<
  UpdateAgentTermsAndConditionsMutation,
  UpdateAgentTermsAndConditionsMutationVariables
> = gql`
  mutation UpdateAgentTermsAndConditionsMutation(
    $input: UpdateAgentTermsAndConditionsInput!
  ) {
    result: updateAgentTermsAndConditions(input: $input) {
      agent {
        id
        termsAndConditions {
          accepted
        }
      }
    }
  }
`

const UPDATE_AGENCY_TERMS_AND_CONDITIONS_MUTATION: TypedDocumentNode<
  UpdateAgencyTermsAndConditionsMutation,
  UpdateAgencyTermsAndConditionsMutationVariables
> = gql`
  mutation UpdateAgencyTermsAndConditionsMutation(
    $input: UpdateAgencyTermsAndConditionsInput!
  ) {
    result: updateAgencyTermsAndConditions(input: $input) {
      agency {
        id
        termsAndConditions {
          accepted
        }
      }
    }
  }
`

const AGENT_ONBOARDING_TERMS_QUERY: TypedDocumentNode<
  AgentOnboardingTermsQuery,
  AgentOnboardingTermsQueryVariables
> = gql`
  query AgentOnboardingTermsQuery(
    $agentTermsLine: String!
    $privacyPolicyLine: String!
  ) {
    me {
      uid
      agent {
        id
        agency {
          id
          name
          termsAndConditions {
            accepted
          }
        }
        termsAndConditions {
          accepted
        }
      }
    }
    agentTerms: termsLine(name: $agentTermsLine) {
      name
      currentRevision {
        id
      }
    }
    privacyPolicy: termsLine(name: $privacyPolicyLine) {
      name
      currentRevision {
        id
      }
    }
  }
`

const validationSchema = Yup.object({
  terms: Yup.boolean()
    .required()
    .oneOf([true], 'Agree to the Propps Terms and Conditions')
    .label('Agree to the Propps Terms & Conditions'),
  privacyPolicy: Yup.boolean()
    .required()
    .oneOf([true], 'Agree to the Privacy Policy')
    .label('Agree to the Privacy Policy'),
})
