import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import {
  Button,
  FixedButtonWrapper,
  Flex,
  FlexItem,
  StackMain,
  ThumbCard,
} from '@propps/ui'
import React from 'react'
import { match as Match, useHistory } from 'react-router-dom'

import { ListAgenciesQuery } from './__generated__/ListAgenciesQuery'

const LIST_AGENCIES_QUERY: TypedDocumentNode<ListAgenciesQuery> = gql`
  query ListAgenciesQuery {
    agencies {
      id
      name
      region
    }
  }
`

export function AgenciesView({ match }: { match: Match<any> }) {
  const history = useHistory()

  const { data } = useQuery(LIST_AGENCIES_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const goToAgency = (agencyId: string) => {
    history.push(`${match.url}/${agencyId}`)
  }

  return (
    <>
      <StackMain variant="app">
        <h1>Agencies</h1>
        {!!data?.agencies?.length ? (
          <Flex>
            {data.agencies.map((agency, index) => (
              <FlexItem
                xs={{ size: 6, display: 'flex' }}
                md={{ size: 3 }}
                key={index}
              >
                <ThumbCard
                  title={agency?.name ?? 'Untitled agency'}
                  desc={agency?.region ?? 'Unknown region'}
                  image="https://firebasestorage.googleapis.com/v0/b/digiprop-2f0e8.appspot.com/o/agent-logos%2Fheader-stockdale.png?alt=media&token=acf80512-d179-48a0-9b79-a07b649624cf"
                  onClick={() => goToAgency(agency?.id ?? '')}
                />
              </FlexItem>
            ))}
          </Flex>
        ) : (
          <FixedButtonWrapper>
            <Button cta pending />
          </FixedButtonWrapper>
        )}
      </StackMain>
      <FixedButtonWrapper>
        <Button cta onClick={() => history.push(match.url + '/create')}>
          Add new agency
        </Button>
      </FixedButtonWrapper>
    </>
  )
}
