import { AuthGuardRedirect, Role, RoleRecord } from '@propps/client'
import { StackApp, StackMenu, StackMenuTrigger, StackNav } from '@propps/ui'
import { complement } from 'ramda'
import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import Menu from './components/menu'
import { AgenciesRoot } from './pages/agencies'
import { AgentRoot } from './pages/agent'
import { AuthRoot } from './pages/auth'
import { DeveloperAppsRoot } from './pages/developer-apps'
import { PageNotFound, Unauthorised } from './pages/error'
import { ListingsRoot } from './pages/listings'
import { OffersRoot } from './pages/offers'
import { PlatformAuthRoot } from './pages/platform-auth'
import { SettingsRoot } from './pages/settings'
import { TermsRoot } from './pages/terms'

function App() {
  const history = useHistory()
  const [stack, setStack] = useState<string[]>([])
  const [showBack, setShowBack] = useState<boolean>(false)

  /**
   * Maintains a stack of location keys corresponding to the history stack.
   *
   * Note this is only accurate if mounted from the start of the app and it
   * remains mounted for the lifetime of the app.
   */
  useEffect(() => {
    const unsub = history.listen((location) => {
      switch (history.action) {
        case 'PUSH':
          // first location when app loads and when pushing onto history
          setStack((value) => [...value, location.pathname!])
          break
        case 'REPLACE':
          // only when using history.replace
          setStack((value) => [
            ...value.slice(0, value.length - 1),
            location.pathname!,
          ])
          break
        case 'POP': {
          setStack((value) => {
            // remove the key on the top of the stack
            value.pop()

            // check that after popping, the key at the top matches the key of the current location
            // if it doesn't match, something is wrong with our stack. dump the stack
            if (value.length && value[value.length - 1] !== location.pathname) {
              return []
            }
            return value
          })
          break
        }
        default:
      }

      // Show back button
      if (
        ['auth', 'onboarding'].some((path) =>
          history.location.pathname.includes(path)
        )
      ) {
        // if it's one of auth/onboarding pages, don't show the back button
        setShowBack(false)
      } else if (
        stack &&
        stack.length > 0 &&
        ['auth', 'onboarding'].some((path) => stack.slice(-1)[0].includes(path))
      ) {
        // if the previous page was auth/onboarding, then don't show it either
        setShowBack(false)
      } else if (
        stack &&
        stack.length > 0 &&
        stack[0] === history.location.pathname
      ) {
        // if it's the first page of the stack
        setShowBack(false)
      } else {
        setShowBack(stack.length > 0)
      }
    })

    return () => {
      unsub()
    }
  }, [stack, history])

  return (
    <StackApp variant={!!isAdmin ? 'admin' : 'app'}>
      <StackNav
        logo
        logoLink="/"
        variant="app"
        showBack={showBack}
        onBack={() => history.goBack()}
        showMenu={
          !['auth', 'onboarding'].some((path) =>
            history.location.pathname?.includes(path)
          )
        }
        customMenu={
          <>
            <StackMenuTrigger />
            <StackMenu>
              <Menu />
            </StackMenu>
          </>
        }
      />
      <Switch>
        <Route exact path="/">
          <Redirect to="/agencies" />
        </Route>
        <Route path="/agencies">
          {({ match }) => (
            <AuthGuardRedirect allow={isAdmin} to="/auth">
              <AgenciesRoot match={match!} />
            </AuthGuardRedirect>
          )}
        </Route>
        <Route path="/listings">
          {({ match }) => (
            <AuthGuardRedirect allow={isAdmin} to="/auth">
              <ListingsRoot match={match!} />
            </AuthGuardRedirect>
          )}
        </Route>
        <Route path="/offers">
          {({ match }) => (
            <AuthGuardRedirect allow={isAdmin} to="/auth">
              <OffersRoot match={match!} />
            </AuthGuardRedirect>
          )}
        </Route>
        <Route path="/settings">
          {({ match }) => (
            <AuthGuardRedirect allow={isAdmin} to="/auth">
              <SettingsRoot match={match!} />
            </AuthGuardRedirect>
          )}
        </Route>
        <Route path="/apps">
          {({ match }) => (
            <AuthGuardRedirect allow={isAdmin} to="/auth">
              <DeveloperAppsRoot match={match!} />
            </AuthGuardRedirect>
          )}
        </Route>
        <Route path="/platform-auth">
          {({ match }) => (
            <PlatformAuthRoot
              match={match!}
              to={{
                [Role.ADMIN]: '/agencies',
                [Role.BUYER]: '/401',
                [Role.AGENT]: '/401',
              }}
              allow={isAdmin}
            />
          )}
        </Route>
        <Route path="/auth">
          {({ match }) => (
            <AuthGuardRedirect allow={isNotSignedIntoRole} to="/">
              <AuthRoot match={match!} />
            </AuthGuardRedirect>
          )}
        </Route>
        <Route path="/agent">
          {({ match }) => <AgentRoot match={match!} />}
        </Route>
        <Route path="/terms">
          {({ match }) => <TermsRoot match={match!} />}
        </Route>
        <Route path="/401">
          <Unauthorised />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </StackApp>
  )
}

export default App

const isAdmin = ({ role }: { role: RoleRecord | null }) => {
  return role?.name === Role.ADMIN
}

// const isAgent = ({ role }: { role: RoleRecord | null }) => {
//   return role?.name === Role.AGENT
// }

const isSignedIntoRole = ({ role }: { role: RoleRecord | null }) => {
  return !!role
}

const isNotSignedIntoRole = complement(isSignedIntoRole)
