import { gql, useApolloClient } from '@apollo/client'
import {
  getPlatformAuthUsers,
  Role,
  useAuth,
  useSearchParams,
} from '@propps/client'
import React, { Fragment, useMemo } from 'react'
import {
  match as Match,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import * as z from 'zod'

import { LoginAuthFlow } from '../../components/login-auth-flow'

export function AuthRoot({ match }: { match: Match }) {
  const apollo = useApolloClient()
  const auth = useAuth()
  const history = useHistory()
  const params = useSearchParams<{ to?: string }>()

  const intent = useMemo(() => {
    const result = AuthHistoryStateSchema.safeParse(history.location.state)
    if (result.success) {
      return result.data.intent
    }
    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAuthComplete = async () => {
    const {
      data: { user },
    } = await apollo.query({
      query: AUTH_ROOT_USER_QUERY,
      fetchPolicy: 'network-only',
    })

    if (intent) {
      history.push(intent.redirect)
      return
    }

    if (user.roles.includes(Role.ADMIN)) {
      await auth.activateRole(Role.ADMIN)
      history.push(params.to || '/agencies')
    } else {
      history.push('/401')
    }
  }

  const title = intent ? (
    <Fragment>
      Welcome to Propps
      <br />
      <span className="light">
        {intent.agent.firstName} {intent.agent.lastName}
      </span>
    </Fragment>
  ) : null

  return (
    <Switch>
      <Route path={match.url} exact>
        {getPlatformAuthUsers() ? (
          <Redirect to={'/platform-auth'} />
        ) : (
          <Redirect to={match.url + '/sign-in'} />
        )}
      </Route>
      <Route path={match.url + '/sign-in'}>
        <LoginAuthFlow onComplete={handleAuthComplete} title={title} />
      </Route>
      <Route path={match.url + '/register'}>Register</Route>
    </Switch>
  )
}

const AUTH_ROOT_USER_QUERY = gql`
  query AuthRootUserQuery {
    user: me {
      uid
      roles
    }
  }
`

const AuthIntentSchema = z.object({
  type: z.literal('register-agent'),
  agent: z.object({
    firstName: z.string(),
    lastName: z.string(),
  }),
  redirect: z.string(),
})

const AuthHistoryStateSchema = z.object({
  intent: AuthIntentSchema.optional(),
})

export type AuthHistoryState = z.TypeOf<typeof AuthHistoryStateSchema>
