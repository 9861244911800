/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import { color, fontSize, size, StackContainer } from '@propps/ui'
import { ReactComponent as Banner } from '../images/invite-the-agent-banner.svg'

const InviteTheAgent = styled.div`
  margin-top: ${size(6)};
  margin-bottom: ${size(8)};
  text-align: center;
`

const BannerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin-bottom: ${size(6)};

  svg {
    height: auto;
    max-width: 100%;
    transform: translateY(5%);
    z-index: 1;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: ${size(16)};
  background-image: linear-gradient(to bottom, #f7f7f7 0%, #fff 100%);
  border-radius: ${size(3)} ${size(3)} 0;
`

const InviteTheAgentBody = styled.div`
  position: relative;
  padding: 0 ${size(3)};
`

const h3 = css`
  width: 100%;
  margin-bottom: ${size(2)};
`

const p = css`
  margin-top: ${size(2)};
  color: ${color.grey};
  font-size: ${fontSize.s};
`

type HeroType = {
  title: string
  subheading?: string
  children?: React.ReactNode
}

export const Hero = ({ title, subheading, children }: HeroType) => (
  <InviteTheAgent>
    <BannerContainer>
      <Banner />
      <Gradient />
    </BannerContainer>
    <InviteTheAgentBody>
      <h3 css={h3}>{title}</h3>
      {subheading && <p css={p}>{subheading}</p>}
      {children && <StackContainer size="xs">{children}</StackContainer>}
    </InviteTheAgentBody>
  </InviteTheAgent>
)
