import styled from '@emotion/styled'
import { fontSize, size } from '@propps/ui'
import React from 'react'

import { ErrorPage } from '../../components/error-page'

export const H2 = styled.h2`
  margin-top: ${size(3)};
  margin-bottom: ${size(2)};

  > div {
    font-weight: normal;
    font-size: ${fontSize.xxl};
  }
`

export function PageNotFound() {
  return (
    <ErrorPage
      title="Page not found"
      errorMessage={`"Lost pages are never found again."`}
      showHomeButton
    />
  )
}

export function Unauthorised() {
  return (
    <ErrorPage
      title="Unauthorized"
      errorMessage={`"It's easier to ask for forgiveness than to ask for permission."`}
      showHomeButton
    />
  )
}
