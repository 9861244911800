import React from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'

import { Listings } from './listings'
import { ListingRoot } from './listing'

export function ListingsRoot({
  match,
}: {
  match: Match<{ appId: string; foreignListingId: string }>
}) {
  return (
    <Switch>
      <Route path={match.path} exact>
        {({ match }) => <Listings match={match!} />}
      </Route>
      <Route path={`${match.path}/:listingId`}>
        {({ match }) => <ListingRoot match={match!} />}
      </Route>
    </Switch>
  )
}
