import { useApolloClient } from '@apollo/client'
import {
  BaseStep,
  ErrorReporting,
  FlowFormProvider,
  RecursiveStep,
  useAuth,
  useFlowForm,
  useSMSVerificationFlow,
} from '@propps/client'
import { StackMain } from '@propps/ui'
import { Form } from 'formik'
import React, { useMemo } from 'react'

import { SoloFormLayout } from '../solo-form-layout'
import { AuthPhoneStep, AuthSMSValidateStep } from './steps'

type Values = {
  phone: string
  smsVerificationCode: string
}

type AuthFormBaseStep<T> = {
  render: (props: { id: string }) => React.ReactElement
  hideButton?: boolean
  buttonLabel?: string
} & BaseStep<T>

type AuthFormStep<T> = RecursiveStep<AuthFormBaseStep<T>>

export function LoginAuthFlow({
  onComplete,
  title,
}: {
  onComplete: (() => void) | (() => Promise<void>)
  title?: React.ReactNode
}) {
  const smsVerification = useSMSVerificationFlow()
  const apollo = useApolloClient()
  const auth = useAuth()

  const steps: AuthFormStep<any>[] = useMemo(
    () => [
      {
        id: 'auth-phone',
        render: ({ id }) => (
          <AuthPhoneStep
            id={id}
            smsVerification={smsVerification}
            title={title}
          />
        ),
      },
      {
        id: 'auth-sms-validate',
        render: ({ id }) => (
          <AuthSMSValidateStep id={id} smsVerification={smsVerification} />
        ),
        buttonLabel: 'Sign in',
      },
    ],
    [smsVerification, title]
  )

  const initialValues: Values = useMemo(
    () => ({
      phone: '',
      smsVerificationCode: '',
    }),
    []
  )

  const form = useFlowForm<Values, AuthFormStep<any>>({
    steps,
    initialValues,
    onSubmit: async (values, helpers) => {
      apollo.cache.modify({
        fields: {
          me: (value, { INVALIDATE }) => INVALIDATE,
        },
      })

      try {
        await onComplete()
      } catch (err) {
        ErrorReporting.report(err)

        // If anything goes wrong, start again
        await auth.signOut()
        helpers.resetForm()
        helpers.goToStep(['auth-phone'])
        smsVerification.reset()
      }
    },
  })
  return (
    <StackMain variant="topCenter">
      <FlowFormProvider value={form}>
        <Form style={{ width: '100%' }}>
          <SoloFormLayout>
            {form.currentStep &&
              React.cloneElement(
                form.currentStep.render({
                  id: form.currentStepInfo.path.join('/'),
                }),
                { key: form.currentStepInfo.path.join('/') }
              )}
            {!form.currentStep?.hideButton && (
              <SoloFormLayout.PrimaryAction
                type="submit"
                label={form.currentStep?.buttonLabel ?? 'Continue'}
                pending={form.formik.isSubmitting}
              />
            )}
          </SoloFormLayout>
        </Form>
      </FlowFormProvider>
      <div
        ref={
          smsVerification.verifierContainerRef as React.RefObject<HTMLDivElement>
        }
      />
    </StackMain>
  )
}
