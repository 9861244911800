import { gql, useQuery } from '@apollo/client'
import { Listing } from '@propps/client'
import {
  Cards,
  ChevronRight,
  Icon,
  LargeHouse,
  StackMain,
  UserCard,
} from '@propps/ui'
import React, { Fragment } from 'react'
import { match as Match, useHistory } from 'react-router-dom'
import { Hero } from '../../components/hero'
import { formatAddress } from '../../util/offer-utils'

export function Listings({ match }: { match: Match }) {
  const { data } = useQuery<{ result: { listings: Listing[] } }>(
    LISTINGS_QUERY,
    {
      variables: {
        input: {},
      },
      fetchPolicy: 'cache-and-network',
    }
  )
  const history = useHistory()

  const goToListing = (id: string) => {
    history.push(`/listings/${id}`)
  }

  return (
    <StackMain variant="app">
      <h1>Listings</h1>
      {data?.result && !data?.result?.listings?.length ? (
        <Hero
          title="No listings"
          subheading="Nothing happens until you decide to make it happen."
        />
      ) : (
        <Cards>
          {data?.result?.listings?.map((listing, index) => (
            <UserCard
              key={index}
              svg={LargeHouse}
              primaryText={formatAddress(listing)}
              secondaryText={listing.source.appId}
              onClick={() => goToListing(listing.id)}
              trailingItems={
                <Fragment>
                  <Icon svg={ChevronRight} />
                </Fragment>
              }
            />
          ))}
        </Cards>
      )}
    </StackMain>
  )
}

const LISTINGS_QUERY = gql`
  query Listings($input: ListingsQueryInput!) {
    result: listings(input: $input) {
      listings {
        id
        property {
          address {
            line1
            city
            state
            postcode
          }
        }
        source {
          appId
          foreignId
        }
      }
    }
  }
`
