import { ApolloProvider } from '@apollo/client'
import {
  APIClientProvider,
  app,
  AuthController,
  createApolloClient,
  firebase,
  FirebaseAppProvider,
} from '@propps/client'
import {
  GlobalStyle,
  Notifications,
  NotificationToast,
  Stacks,
  ScrollToTopOnMount,
} from '@propps/ui'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'

import App from './App'
import { ErrorBoundary } from './components/error-boundary'
import * as serviceWorker from './serviceWorker'

const SERVICES_BASEPATH = process.env.REACT_APP_SERVICES_BASEPATH as string
const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT!

const history = createBrowserHistory()

const client = createApolloClient(app.auth(), GRAPHQL_ENDPOINT)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <FirebaseAppProvider value={app}>
        <ApolloProvider client={client}>
          <APIClientProvider basepath={SERVICES_BASEPATH}>
            <ToastProvider
              components={{
                Toast: NotificationToast,
                ToastContainer: Notifications,
              }}
              autoDismissTimeout={10000} //in milliseconds
            >
              <AuthController>
                <Router history={history}>
                  <ScrollToTopOnMount />
                  <GlobalStyle />
                  <Stacks>
                    <App />
                  </Stacks>
                </Router>
              </AuthController>
            </ToastProvider>
          </APIClientProvider>
        </ApolloProvider>
      </FirebaseAppProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

firebase.auth().settings.appVerificationDisabledForTesting =
  process.env.NODE_ENV !== 'production'
