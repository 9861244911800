import React from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'

import { OfferView } from './offer'

export function OffersRoot({ match }: { match: Match<any> }) {
  return (
    <Switch>
      <Route exact path={`${match.path}/:offerId`}>
        {({ match }) => <OfferView offerId={match!.params.offerId} />}
      </Route>
    </Switch>
  )
}
