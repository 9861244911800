/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from '@emotion/styled'
import {
  getPlatformAuthUsers,
  PlatformAuthUser,
  Role,
  signInWithPlatformAuth,
  useAPIClient,
  useAuth,
} from '@propps/client'
import { LargeUser, size, StackMain, UserCard } from '@propps/ui'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const CtaContainer = styled.div`
  margin-top: ${size(6)};
  text-align: center;

  span:not(:last-child) {
    display: block;
    margin-bottom: ${size(2)};
  }
`

export type platformUser = {
  name: string
  phoneNumber: string
  onUserSelected: () => void
}

export type platformUsers = platformUser[]

export const PlatformUser = ({ to }: { to: Record<Role, string> }) => {
  const client = useAPIClient()
  const history = useHistory()
  const auth = useAuth()

  const [platformUsers, setPlatformUsers] = useState<PlatformAuthUser[]>([])

  useEffect(() => {
    ;(async () => {
      const users = await getPlatformAuthUsers()
      if (users) {
        setPlatformUsers(users)
      }
    })()
  }, [])

  const next = () => {
    if (!auth?.role) {
      history.replace('/auth/register')
      return
    }
    history.replace(to[auth?.role?.name])
  }

  const onOtherUser = () => {
    history.replace('/auth/sign-in')
    return
  }
  const onNewAccount = () => {}
  const onUserSelected = async (platformAuthUser: PlatformAuthUser) => {
    await signInWithPlatformAuth(platformAuthUser, client, auth)
    next()
  }

  return (
    <StackMain variant="topCenter">
      <h2>Sign in to Propps</h2>

      {platformUsers?.map((platformAuthUser, index) => {
        return (
          <UserCard
            key={index}
            svg={LargeUser}
            primaryText={platformAuthUser.displayName}
            secondaryText={platformAuthUser.name}
            onClick={() => onUserSelected(platformAuthUser)}
          />
        )
      })}

      <CtaContainer>
        <span>
          <a href="#" onClick={onOtherUser}>
            Sign in as someone else
          </a>
        </span>
        <span>
          <a href="#" onClick={onNewAccount}>
            Create a new account
          </a>
        </span>
      </CtaContainer>
    </StackMain>
  )
}
