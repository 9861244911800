import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import {
  ArrowOutUp,
  Button,
  Cards,
  ChevronRight,
  FixedButtonWrapper,
  Icon,
  LargeDocument,
  LargeDocuments,
  LargeHouse,
  Plus,
  StackModal,
  UserCard,
  useStackModalState,
} from '@propps/ui'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { Hero } from '../../../../components/hero'
import { AgencyImportListings } from './import'
import { AgencyListingsQuery } from './__generated__/AgencyListingsQuery'
import { AgencyListings_Agency } from './__generated__/AgencyListings_Agency'
import { AgencyListings_Listing } from './__generated__/AgencyListings_Listing'

export function AgencyListings({
  agency,
}: {
  agency: AgencyListings_Agency | null | undefined
}) {
  const history = useHistory()
  const createListingModal = useStackModalState()
  const importListingsModal = useStackModalState()

  const { data } = useQuery(AGENCY_LISTINGS_QUERY, {
    variables: { id: agency?.id },
    skip: !agency,
  })

  const goToListing = (id: string) => {
    history.push(`/listings/${id}`)
  }

  return (
    <Fragment>
      {!data?.agency?.listings?.length ? (
        <Hero
          title="No listings"
          subheading="Nothing happens until you decide to make it happen."
        />
      ) : (
        <Cards>
          {data.agency.listings.map((listing, index) => (
            <UserCard
              key={index}
              svg={LargeHouse}
              primaryText={formatAddress(listing)}
              secondaryText=""
              onClick={() => goToListing(listing.id)}
              trailingItems={
                <Fragment>
                  <Icon svg={ChevronRight} />
                </Fragment>
              }
            />
          ))}
        </Cards>
      )}
      <FixedButtonWrapper>
        <StackModal.Disclosure
          {...createListingModal}
          as={Button}
          cta
          pending={!agency}
          disabled={!agency}
        >
          Add new listing
        </StackModal.Disclosure>
      </FixedButtonWrapper>
      <StackModal
        size="s"
        state={createListingModal}
        title="Create new listing"
      >
        <Cards>
          <UserCard
            svg={LargeDocument}
            primaryText="Single listing"
            secondaryText="with data entry"
            trailingItems={<Icon svg={Plus} />}
            onClick={() => {}}
            tabIndex={0}
          />
          <UserCard
            svg={LargeDocuments}
            primaryText="Bulk import"
            secondaryText="using a .csv file"
            trailingItems={<Icon svg={ArrowOutUp} />}
            onClick={() => {
              importListingsModal.show()
            }}
            tabIndex={0}
          />
        </Cards>
      </StackModal>
      <StackModal size="s" state={importListingsModal} title="Import listings">
        {agency ? (
          <AgencyImportListings
            agency={agency}
            onSuccess={() => importListingsModal.hide()}
          />
        ) : null}
      </StackModal>
    </Fragment>
  )
}

AgencyListings.fragments = {
  Listing: gql`
    fragment AgencyListings_Listing on Listing {
      id
      property {
        address {
          line1
          city
          state
          postcode
        }
      }
    }
  `,
  Agency: gql`
    fragment AgencyListings_Agency on Agency {
      id
      name
      ...AgencyImportListings_Agency
    }
    ${AgencyImportListings.fragments.Agency}
  `,
}

const AGENCY_LISTINGS_QUERY: TypedDocumentNode<AgencyListingsQuery> = gql`
  query AgencyListingsQuery($id: ID!) {
    agency(id: $id) {
      id
      listings {
        ...AgencyListings_Listing
      }
    }
  }
  ${AgencyListings.fragments.Listing}
`

const formatAddress = (listing: AgencyListings_Listing) => {
  if (!listing.property || !listing.property.address) {
    return ''
  }
  const address = listing.property.address
  return `${address.line1}, ${address.city ?? ''}, ${address.state ?? ''} ${
    address.postcode ?? ''
  }`
}
