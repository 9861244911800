import {
  AddToast,
  AppearanceTypes,
  RemoveToast,
  UpdateToast,
  useToasts,
} from 'react-toast-notifications'
import { Notification } from '@propps/ui'
import React from 'react'

export const useToast = () => {
  const { addToast, removeToast, updateToast } = useToasts()
  return {
    addSuccessNotification: ({
      label,
      description,
      progress,
      updateToastId,
    }: {
      label: string
      description?: string
      progress?: boolean
      updateToastId?: string | undefined
    }) => {
      return addNotification({
        label,
        description,
        updateToastId,
        progress,
        status: 'success',
        addToast,
        updateToast,
        removeToast,
      })
    },
    addErrorNotification: ({
      label,
      description,
      updateToastId,
    }: {
      label: string
      description?: string
      updateToastId?: string | undefined
    }) => {
      return addNotification({
        label,
        description,
        updateToastId,
        status: 'error',
        addToast,
        updateToast,
        removeToast,
      })
    },
    addInfoNotification: ({
      label,
      description,
      progress,
      updateToastId,
    }: {
      label: string
      description?: string
      progress?: boolean
      updateToastId?: string | undefined
    }) => {
      return addNotification({
        label,
        description,
        updateToastId,
        progress,
        status: 'info',
        addToast,
        updateToast,
        removeToast,
      })
    },
    addWarningNotification: ({
      label,
      description,
      progress,
      updateToastId,
    }: {
      label: string
      description?: string
      progress?: boolean
      updateToastId?: string | undefined
    }) => {
      return addNotification({
        label,
        description,
        updateToastId,
        progress,
        status: 'warning',
        addToast,
        updateToast,
        removeToast,
      })
    },
  }
}

const addNotification = ({
  label,
  description,
  progress,
  updateToastId,
  status,
  addToast,
  updateToast,
  removeToast,
}: {
  label: string
  description?: string
  progress?: boolean
  updateToastId?: string | undefined
  status: AppearanceTypes
  addToast: AddToast
  updateToast: UpdateToast
  removeToast: RemoveToast
}) => {
  if (updateToastId) {
    updateToast(updateToastId, {
      // @ts-ignore
      content: (
        <Notification
          label={label}
          description={description}
          progress={progress}
        />
      ),
      appearance: status,
      autoDismiss: status === 'success',
      onDismiss: (id) => removeToast(id),
    })
    return updateToastId
  }
  return (addToast(
    <Notification
      label={label}
      description={description}
      progress={progress}
    />,
    {
      appearance: status,
      autoDismiss: status === 'success',
      onDismiss: (id) => removeToast(id),
    }
  ) as unknown) as string
}
