import { gql } from '@apollo/client'
import {
  AGENT_TERMS_LINE,
  BUYER_TERMS_LINE,
  PRIVACY_POLICY_LINE,
} from '@propps/client'
import {
  Button,
  FixedButtonWrapper,
  StackMain,
  TextPlaceholder,
} from '@propps/ui'
import { format as formatDate, parseISO } from 'date-fns'
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { TermsRevisionView_TermsRevision } from './__generated__/TermsRevisionView_TermsRevision'

export function TermsRevisionView({
  revision,
}: {
  revision?: TermsRevisionView_TermsRevision | null
}) {
  const history = useHistory()

  // Scroll to hash fragment if present
  const [isLoaded, setLoaded] = useState(false)
  useEffect(() => {
    if (revision && !isLoaded) {
      if (history.location.hash) {
        let el = document.getElementById(history.location.hash.substr(1))

        if (el) {
          el.scrollIntoView()
        }
      }

      setLoaded(true)
    }
  }, [revision, isLoaded, history.location])

  return (
    <Fragment>
      {revision ? (
        <>
          <StackMain variant="app">
            <h1>{formatDocumentName(revision.line.name)}</h1>
            <article>
              <p>
                <b>Version {revision.versionName}</b>
                <br />
                Last updated{' '}
                {revision ? formatRevisionDate(revision) : <TextPlaceholder />}
              </p>
              <hr />
              <div
                dangerouslySetInnerHTML={{
                  __html: revision.contentHTML || '',
                }}
              />
            </article>
          </StackMain>
          {window.opener !== null && (
            <FixedButtonWrapper>
              <Button cta label="Close" onClick={() => window.close()} />
            </FixedButtonWrapper>
          )}
        </>
      ) : null}
    </Fragment>
  )
}

TermsRevisionView.fragments = {
  TermsRevision: gql`
    fragment TermsRevisionView_TermsRevision on TermsRevision {
      id
      line {
        name
      }
      contentHTML
      versionName
      createdAt
    }
  `,
}

function formatRevisionDate(revision: TermsRevisionView_TermsRevision) {
  return formatDate(parseISO(revision.createdAt), 'dd MMM yyy')
}

function formatDocumentName(line: string) {
  switch (line) {
    case BUYER_TERMS_LINE:
    case AGENT_TERMS_LINE:
      return 'Terms & Conditions'
    case PRIVACY_POLICY_LINE:
      return 'Privacy Policy'
    default:
      return null
  }
}
