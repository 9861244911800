import React from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'

import { AgenciesView } from './agencies'
import { AgencyRoot } from './agency'
import { CreateAgency } from './create-agency'

export function AgenciesRoot({ match }: { match: Match }) {
  return (
    <Switch>
      <Route exact path={match.path}>
        {({ match }) => <AgenciesView match={match!} />}
      </Route>
      <Route path={`${match.path}/create`}>
        {({ match }) => <CreateAgency match={match!} />}
      </Route>
      <Route path={`${match.path}/:agencyId`}>
        {({ match }) => <AgencyRoot match={match!} />}
      </Route>
    </Switch>
  )
}
