import { gql } from '@apollo/client'
import { StackMain, Tabs, TextPlaceholder } from '@propps/ui'
import React from 'react'
import {
  match as Match,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import { AgencyAgents } from './agents/agents'
import { AgencyListings } from './listings/listings'
import { AgencySettings } from './settings'
import { AgencyView_Agency } from './__generated__/AgencyView_Agency'

export function AgencyView({
  agency,
  match,
}: {
  agency: AgencyView_Agency
  match: Match
}) {
  const history = useHistory()

  const match2 = useRouteMatch<{ subpath: string }>(match.path + '/:subpath')

  return (
    <StackMain variant="app">
      <h1>
        {(
          <>
            {agency.name}
            <br />
            <span className="light">{agency.region}</span>
          </>
        ) ?? <TextPlaceholder />}
      </h1>
      <Tabs
        tabs={[
          {
            label: 'Listings',
            onClick: () => {
              history.push(match.url + '/listings')
            },
            active: match2?.params.subpath === 'listings',
          },
          {
            label: 'Agents',
            onClick: () => {
              history.push(match.url + '/agents')
            },
            active: match2?.params.subpath === 'agents',
          },
          {
            label: 'Settings',
            onClick: () => {
              history.push(match.url + '/settings')
            },
            active: match2?.params.subpath === 'settings',
          },
        ]}
      />

      <Switch>
        <Route path={match.path} exact>
          {!agency?.listings?.length ? (
            <Redirect to={`${match.url}/agents`} />
          ) : (
            <Redirect to={match.url + '/listings'} />
          )}
        </Route>
        <Route path={`${match.path}/listings`}>
          {({ match }) => <AgencyListings agency={agency} />}
        </Route>
        <Route path={`${match.path}/agents`}>
          {({ match }) => (
            <AgencyAgents
              match={match!}
              agency={agency}
              agents={agency?.agents}
            />
          )}
        </Route>
        <Route path={`${match.path}/settings`}>
          {({ match }) => <AgencySettings match={match!} agency={agency} />}
        </Route>
      </Switch>
    </StackMain>
  )
}

AgencyView.fragments = {
  Agency: gql`
    fragment AgencyView_Agency on Agency {
      id
      name
      ...AgencyListings_Agency
      ...AgencyAgents_Agency
      ...AgencySettings_Agency
      agents {
        ...AgencyAgents_Agent
      }
      listings {
        id
      }
    }
    ${AgencyListings.fragments.Agency}
    ${AgencyAgents.fragments.Agency}
    ${AgencyAgents.fragments.Agent}
    ${AgencySettings.fragments.Agency}
  `,
}
