import { gql, TypedDocumentNode, useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import {
  Button,
  FixedButtonWrapper,
  Input,
  Label,
  ListItem,
  ListWrapper,
} from '@propps/ui'
import { useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import { useToast } from '../../../../components/toast'
import {
  CreateAgentMutation,
  CreateAgentMutationVariables,
} from './__generated__/CreateAgentMutation'
import { CreateUpdateAgentForm_Agency } from './__generated__/CreateUpdateAgentForm_Agency'
import { CreateUpdateAgentForm_Agent } from './__generated__/CreateUpdateAgentForm_Agent'
import {
  UpdateAgentMutation,
  UpdateAgentMutationVariables,
} from './__generated__/UpdateAgentMutation'
import { getErrors } from '@propps/client'

/**
 * Form for creating or updating an agent.
 *
 * Supply the `agent` prop for update, otherwise a new agent will be created
 */
export function CreateUpdateAgentForm({
  agent,
  agency,
  onSuccess,
  buttonText,
  children,
}: {
  agent?: CreateUpdateAgentForm_Agent | null
  agency: CreateUpdateAgentForm_Agency
  onSuccess?: (agent: CreateUpdateAgentForm_Agent) => void
  buttonText: string
  buttonPosition?: string
  children?: React.ReactNode
}) {
  const {
    addInfoNotification,
    addSuccessNotification,
    addErrorNotification,
  } = useToast()

  const [update] = useMutation(UPDATE_AGENT_MUTATION)
  const [create] = useMutation(CREATE_AGENT_MUTATION, {
    update: (cache, result) => {
      if (!result.data) return

      cache.modify({
        id: cache.identify({ __typename: 'Agency', id: agency.id }),
        fields: {
          agents: (value) => {
            if (!(value && Array.isArray(value))) return value

            const ref = cache.writeFragment({
              id: cache.identify({
                __typename: 'Agent',
                id: result.data!.result.agent.id,
              }),
              fragment: CreateUpdateAgentForm.fragments.Agent,
              data: result.data!.result.agent,
            })
            return [...value, ref]
          },
        },
      })
    },
  })

  const formik = useFormik<{
    agent: Omit<CreateUpdateAgentForm_Agent, 'id' | '__typename'>
  }>({
    initialValues: {
      agent: agent || {
        email: '',
        firstName: '',
        lastName: '',
        phone: null,
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      if (agent) {
        const progressToastId = addInfoNotification({
          label: 'Updating agent.',
          progress: true,
        })
        try {
          const fetchResult = await update({
            variables: {
              input: {
                id: agent.id,
                firstName: values.agent.firstName,
                lastName: values.agent.lastName,
                email: values.agent.email,
              },
            },
          })
          if (fetchResult.errors) {
            addErrorNotification({
              label: 'Agent update error',
              description: fetchResult.errors[0].message,
              updateToastId: progressToastId,
            })
            return
          }
          addSuccessNotification({
            label: 'Agent updated',
            description: 'Agent updated successfully',
            updateToastId: progressToastId,
          })
          helpers.resetForm()
          onSuccess && onSuccess(fetchResult.data!.result.agent)
        } catch (e) {
          addErrorNotification({
            label: 'Agent update error',
            description: e.message,
            updateToastId: progressToastId,
          })
          return
        }
      } else {
        const progressToastId = addInfoNotification({
          label: 'Creating agent',
          progress: true,
        })
        try {
          const result = await create({
            variables: {
              input: {
                agencyId: agency.id,
                firstName: values.agent.firstName,
                lastName: values.agent.lastName,
                email: values.agent.email,
              },
            },
          })
          if (result.errors) {
            addErrorNotification({
              label: 'Agent creation error',
              description: result.errors[0].message,
              updateToastId: progressToastId,
            })
            return
          }
          addSuccessNotification({
            label: 'Agent created',
            description: 'Agent created successfully',
            updateToastId: progressToastId,
          })
          helpers.resetForm()
          onSuccess && onSuccess(result.data!.result.agent)
        } catch (e) {
          console.log(e)
          addErrorNotification({
            label: 'Agent creation error',
            description: e.message,
            updateToastId: progressToastId,
          })
          return
        }
      }
    },
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      css={css`
        width: 100%;
      `}
    >
      <ListWrapper>
        <ListItem>
          <Label>First name</Label>
          <Input
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`agent.firstName`)}
            errors={getErrors(formik, 'agent.firstName')}
          />
        </ListItem>
        <ListItem>
          <Label>Last name</Label>
          <Input
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`agent.lastName`)}
            errors={getErrors(formik, 'agent.lastName')}
          />
        </ListItem>
        <ListItem>
          <Label>Email</Label>
          <Input
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`agent.email`)}
            errors={getErrors(formik, `agent.email`)}
            inputMode="email"
          />
        </ListItem>
      </ListWrapper>

      {children}

      <FixedButtonWrapper position="inherit">
        <Button
          cta
          type="submit"
          disabled={formik.isSubmitting}
          pending={formik.isSubmitting}
        >
          {buttonText}
        </Button>
      </FixedButtonWrapper>
    </form>
  )
}

CreateUpdateAgentForm.fragments = {
  Agent: gql`
    fragment CreateUpdateAgentForm_Agent on Agent {
      id
      phone
      email
      firstName
      lastName
    }
  `,
  Agency: gql`
    fragment CreateUpdateAgentForm_Agency on Agency {
      id
    }
  `,
}

const CREATE_AGENT_MUTATION: TypedDocumentNode<
  CreateAgentMutation,
  CreateAgentMutationVariables
> = gql`
  mutation CreateAgentMutation($input: CreateAgentInput!) {
    result: createAgent(input: $input) {
      agent {
        ...CreateUpdateAgentForm_Agent
      }
    }
  }
  ${CreateUpdateAgentForm.fragments.Agent}
`

const UPDATE_AGENT_MUTATION: TypedDocumentNode<
  UpdateAgentMutation,
  UpdateAgentMutationVariables
> = gql`
  mutation UpdateAgentMutation($input: UpdateAgentInput!) {
    result: updateAgent(input: $input) {
      agent {
        ...CreateUpdateAgentForm_Agent
      }
    }
  }
  ${CreateUpdateAgentForm.fragments.Agent}
`

const validationSchema = Yup.object({
  agent: Yup.object({
    email: Yup.string()
      .trim()
      .email('Invalid email.')
      .required()
      .label('Email'),
    firstName: Yup.string().trim().required().label('First name'),
    lastName: Yup.string().trim().required().label('Last name'),
  }),
})
