import React from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'

import { CreateDeveloperApp } from './create-developer-app'
import { DeveloperApp } from './developer-app'
import { DeveloperAppsView } from './developer-apps'

export function DeveloperAppsRoot({ match }: { match: Match }) {
  return (
    <Switch>
      <Route exact path={match.path}>
        {({ match }) => <DeveloperAppsView match={match!} />}
      </Route>
      <Route path={match.path + '/create'}>
        {({ match }) => <CreateDeveloperApp match={match!} />}
      </Route>
      <Route path={match.path + '/:appId'}>
        {({ match }) => <DeveloperApp match={match!} />}
      </Route>
    </Switch>
  )
}
