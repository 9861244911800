// TODO useful for sorting
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  Listing,
  Offer,
  OfferBuyer,
  UserVerificationStatus,
} from '@propps/client'

import { format, isFuture, isValid } from 'date-fns'

export function isConditionalOffer(offer: Offer): boolean {
  return !!(
    offer.offer.conditions.conditions.length ||
    (offer.offer.conditions.customCondition &&
      offer.offer.conditions.customCondition.trim().length === 0)
  )
}

export function isExpiredOffer(offer: Offer): boolean {
  if (!offer.offer.expiry) {
    return false
  }

  if (typeof offer.offer.expiry !== 'object' || !isValid(offer.offer.expiry)) {
    return false
  }

  if (!offer.offer.expiry || isFuture(offer.offer.expiry)) {
    return false
  }

  return true
}

// Will return true if there is no status or there is an explicit active status
export function isActiveOffer(offer: Offer): boolean {
  return !(offer.status && offer.status !== 'active')
}

export function compareOffers(a: Offer, b: Offer) {
  const expiredA = isExpiredOffer(a) || !isActiveOffer(a)
  const expiredB = isExpiredOffer(b) || !isActiveOffer(b)
  if (expiredA !== expiredB) {
    return !expiredA ? -1 : 1
  }
  if (a.offer.amount === b.offer.amount) {
    const conditionalOfferA = isConditionalOffer(a)
    const conditionalOfferB = isConditionalOffer(b)
    if (conditionalOfferA === conditionalOfferB) {
      // This can be expanded to finance pre-approval
      return 0
    }
    return !conditionalOfferA ? -1 : 1
  }
  if (a.offer.amount === b.offer.amount) {
    return 0
  }
  return a.offer.amount > b.offer.amount ? -1 : 1
}

export function findHighestOfferAmount(offers: Offer[]) {
  return offers
    .map((offer) => offer.offer.amount)
    .reduce((a, b) => Math.max(Number(a), Number(b)), 0)
}

export function findPrimaryBuyer(offer: Offer): OfferBuyer | undefined {
  return offer.buyers.signatories.find(
    (signatory) => signatory.id === offer.buyers.primaryBuyerId
  )
}

/**
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 * @param num number for format, only for integers
 */
export function formatThousandsSeparators(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatDollarAmount(num: number) {
  return '$ ' + formatThousandsSeparators(num)
}

export function formatDays(value: string) {
  const number = parseInt(value)
  if (!isNaN(number)) {
    return `${number} days`
  }
  return value
}

export function formatDate(value: Date) {
  return format(value, 'dd/mm/yyyy')
}

export function getIdentityVerificationStatusMessage(offer: Offer) {
  const status = offer.buyers.signatories[0].verificationStatus
  const identityDocument = offer.buyers.signatories[0].identityDocument

  let message = ''

  if (identityDocument.idType === 'DRIVERS_LICENCE') {
    message += `${identityDocument.region} driver's licence`
  } else if (identityDocument.idType === 'PASSPORT') {
    message += `${identityDocument.country} passport`
  } else {
    return ''
  }

  if (status === UserVerificationStatus.VERIFIED) {
    message += ' verified.'
  } else if (status === UserVerificationStatus.PENDING) {
    message += ' verfication pending.'
  } else if (status === UserVerificationStatus.FAILED) {
    message += ' verification failed.'
  }

  return message
}

export function getFinanceStatusMessage(offer: Offer) {
  switch (offer.offer.finance.status) {
    case 'preapproved':
      return 'Pre-approved'
    case 'not-arranged':
      return 'Not ready'
    case 'not-applicable':
      return 'Not required'
    case '':
      return 'Information not provided'
    default:
      return offer.offer.finance.status
  }
}

export function getFinanceInstitutionDisplayName(offer: Offer) {
  if (offer.offer.finance.institution === 'other') {
    return offer.offer.finance.institutionOther
  }

  switch (offer.offer.finance.institution) {
    case 'anz':
      return 'ANZ'
    case 'nab':
      return 'NAB'
    case 'cba':
      return 'Commonwealth Bank'
    case 'westpac':
      return 'Westpac Bank'
    case 'macquarie':
      return 'Macquarie Bank'
    case 'bendigo':
      return 'Bendigo Bank'
    case 'amp':
      return 'Bankwest'
    case 'melbourne':
      return 'Bank of Melbourne'
    case 'me':
      return 'ME (Members Equity) Bank'
    case 'boq':
      return 'Bank of Queensland'
    default:
      return offer.offer.finance.institution
  }
}

export function formatAddress(listing: Listing) {
  if (!listing.property || !listing.property.address) {
    return ''
  }
  const address = listing.property.address
  return `${address.line1}, ${address.city ?? ''}, ${address.state ?? ''} ${
    address.postcode ?? ''
  }`
}
