import { gql } from '@apollo/client'
import {
  Button,
  FixedButtonWrapper,
  Flex,
  FlexItem,
  LargeUser,
  StackModal,
  UserCard,
  useStackModalState,
} from '@propps/ui'
import React, { Fragment, useEffect } from 'react'
import { match as Match, useHistory, useRouteMatch } from 'react-router-dom'

import { Hero } from '../../../../components/hero'
import { CreateUpdateAgentForm } from './create-update-agent'
import { AgencyAgents_Agency } from './__generated__/AgencyAgents_Agency'
import { AgencyAgents_Agent } from './__generated__/AgencyAgents_Agent'

export function AgencyAgents({
  match,
  agency,
  agents,
}: {
  match: Match
  agency: AgencyAgents_Agency
  agents: AgencyAgents_Agent[] | null | undefined
}) {
  const history = useHistory()
  const createAgentModal = useStackModalState()
  const editAgentModal = useStackModalState()

  const agentMatch = useRouteMatch<{ agentId: string }>(
    match.path + '/:agentId'
  )

  // sync route to agent edit modal
  useEffect(() => {
    if (agentMatch && agentMatch.params.agentId !== 'create') {
      editAgentModal.show()
    } else {
      editAgentModal.hide()
    }
  }, [agentMatch, editAgentModal])

  // sync route to agent create modal
  useEffect(() => {
    if (agentMatch && agentMatch.params.agentId === 'create') {
      createAgentModal.show()
    } else {
      createAgentModal.hide()
    }
  })

  return (
    <Fragment>
      {!agents?.length ? (
        <Hero
          title="Invite an agent"
          subheading="Let them in. They’re waiting."
        />
      ) : (
        <Flex>
          {agents?.map((agent, index) => {
            return (
              <FlexItem
                xs={{ size: 6, display: 'flex' }}
                md={{ size: 3 }}
                key={index}
              >
                <UserCard
                  variant={agent.status === 'invited' ? 'dashed' : undefined}
                  svg={LargeUser}
                  primaryText={`${agent.firstName} ${agent.lastName}`}
                  secondaryText={
                    <Fragment>
                      {agent.email}
                      <br />
                      {agent.phone}
                    </Fragment>
                  }
                  onClick={() => {
                    history.push(match.url + '/' + agent.id)
                  }}
                />
              </FlexItem>
            )
          })}
        </Flex>
      )}
      <FixedButtonWrapper>
        <StackModal.Disclosure
          {...createAgentModal}
          as={Button}
          cta
          pending={!agency}
          disabled={!agency}
          onClick={() => {
            history.push(match.url + '/create')
          }}
        >
          Invite agent
        </StackModal.Disclosure>
      </FixedButtonWrapper>

      <StackModal
        size="l"
        centered
        state={{ ...createAgentModal, hide: () => history.replace(match.url) }}
        title="Invite new agent"
      >
        <CreateUpdateAgentForm
          buttonText="Invite agent"
          agency={agency}
          onSuccess={() => history.replace(match.url)}
        >
          <p>
            Agents invited to this agency will be able to view all listings and
            offers.
          </p>
        </CreateUpdateAgentForm>
      </StackModal>

      <StackModal
        size="l"
        centered
        state={{ ...editAgentModal, hide: () => history.replace(match.url) }}
        title="Edit agent"
      >
        {agentMatch && (
          <CreateUpdateAgentForm
            agent={agents?.find(
              (agent) => agent.id === agentMatch?.params.agentId
            )}
            agency={agency}
            onSuccess={() => history.replace(match.url)}
            buttonText="Update agent"
          />
        )}
      </StackModal>
    </Fragment>
  )
}

AgencyAgents.fragments = {
  Agent: gql`
    fragment AgencyAgents_Agent on Agent {
      id
      status
      phone
      email
      firstName
      lastName
    }
  `,
  Agency: gql`
    fragment AgencyAgents_Agency on Agency {
      id
      name
      ...CreateUpdateAgentForm_Agency
    }
    ${CreateUpdateAgentForm.fragments.Agency}
  `,
}
