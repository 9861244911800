import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import { StackBreadcrumb, StackMain, Tabs, TextPlaceholder } from '@propps/ui'
import React from 'react'
import {
  Link,
  match as Match,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'

import { ListingOffers } from './offers'
import { ListingSettings } from './settings'
import {
  ListingQuery,
  ListingQueryVariables,
} from './__generated__/ListingQuery'
import { ListingCampaign } from './campaign'
import { ListingAgents } from './agents'

export function ListingRoot({
  match,
}: {
  match: Match<{ listingId: string }>
}) {
  const history = useHistory()
  const { data } = useQuery(LISTING_QUERY, {
    variables: {
      id: match.params.listingId,
    },
    fetchPolicy: 'cache-and-network',
  })

  const listing = data?.listing

  const match2 = useRouteMatch<{ subpath: string }>(match.path + '/:subpath')

  // TODO: Need to support multiple agencies per listing
  const agency = listing?.agencies?.[0]

  return (
    <StackMain
      variant="app"
      breadcrumb={
        <StackBreadcrumb
          levels={[
            <Link to="/agencies">Agencies</Link>,
            agency ? (
              <Link to={`/agencies/${agency.id}`}>
                {agency.name} {agency.region && `(${agency.region})`}
              </Link>
            ) : null,
          ]}
        />
      }
    >
      <h1>{listing ? listing?.property.address.line1 : <TextPlaceholder />}</h1>
      <Tabs
        tabs={[
          {
            label: 'Offers',
            onClick: () => {
              history.push(`${match.url}/offers`)
            },
            active: match2?.params.subpath === 'offers',
          },
          {
            label: 'Campaign',
            onClick: () => {
              history.push(`${match.url}/campaign`)
            },
            active: match2?.params.subpath === 'campaign',
          },
          {
            label: 'Agents',
            onClick: () => {
              history.push(`${match.url}/agents`)
            },
            active: match2?.params.subpath === 'agents',
          },
          {
            label: 'Settings',
            onClick: () => {
              history.push(`${match.url}/settings`)
            },
            active: match2?.params.subpath === 'settings',
          },
        ]}
      />
      <Switch>
        <Route path={match.path} exact>
          <Redirect to={`${match.url}/offers`} />
        </Route>
        <Route path={`${match.path}/offers`}>
          {({ match }) =>
            listing ? (
              <ListingOffers listingId={listing.id} match={match!} />
            ) : null
          }
        </Route>
        <Route path={`${match.path}/campaign`}>
          {() => (listing ? <ListingCampaign listing={listing} /> : null)}
        </Route>
        <Route path={`${match.path}/agents`}>
          {({ match }) =>
            listing ? <ListingAgents listing={listing} match={match!} /> : null
          }
        </Route>
        <Route path={`${match.path}/settings`}>
          {() => (listing ? <ListingSettings listing={listing} /> : null)}
        </Route>
      </Switch>
    </StackMain>
  )
}

const LISTING_QUERY: TypedDocumentNode<
  ListingQuery,
  ListingQueryVariables
> = gql`
  query ListingQuery($id: ID!) {
    listing(id: $id) {
      id
      property {
        address {
          line1
        }
      }
      agencies {
        id
        name
        region
      }
      ...ListingCampaign_Listing
      ...ListingSettings_Listing
      ...ListingAgents_Listing
    }
  }
  ${ListingCampaign.fragments.Listing}
  ${ListingSettings.fragments.Listing}
  ${ListingAgents.fragments.Listing}
`
