import { gql, TypedDocumentNode, useMutation } from '@apollo/client'
import {
  LargeDocument,
  Section,
  SectionHeader,
  ToggleSwitch,
  UserCard,
} from '@propps/ui'
import React, { Fragment, useState } from 'react'

import { useToast } from '../../../../components/toast'
import { ListingPublish_Listing } from './__generated__/ListingPublish_Listing'
import {
  UpdateListingPublishMutation,
  UpdateListingPublishMutationVariables,
} from './__generated__/UpdateListingPublishMutation'

export function ListingPublish({
  listing,
}: {
  listing: ListingPublish_Listing
}) {
  const cosAvailable = listing.documents.some((doc) => doc.name === 'COS')

  const [updateListing] = useMutation(UPDATE_LISTING_PUBLISH)

  const {
    addInfoNotification,
    addSuccessNotification,
    addErrorNotification,
  } = useToast()

  const [listingPublished, setListingPublished] = useState(listing.published)

  const updateListingPublishing = async () => {
    const updatedPublishedValue = !listingPublished
    if (process.env.REACT_APP_NON_BINDING_OFFERS_ENABLED !== 'true') {
      if (!cosAvailable && updatedPublishedValue) {
        addErrorNotification({
          label: 'Failed to publish listing',
          description:
            "A listing can't be published without a Contract of Sale.",
        })
        return
      }
    }
    const progressToastId = addInfoNotification({
      label: `${
        updatedPublishedValue ? 'Publishing' : 'Unpublishing'
      } listing...`,
      progress: true,
    })
    try {
      const fetchResult = await updateListing({
        variables: {
          input: {
            id: listing.id,
            published: updatedPublishedValue,
          },
        },
      })
      if (fetchResult.errors) {
        addErrorNotification({
          label: 'Failed to publish listing',
          description: fetchResult.errors[0].message,
          updateToastId: progressToastId,
        })
        return
      }

      addSuccessNotification({
        label: `Listing ${updatedPublishedValue ? 'published' : 'unpublished'}`,
        description: `The listing was ${
          updatedPublishedValue ? 'published' : 'unpublished'
        } successfully.`,
        updateToastId: progressToastId,
      })
      setListingPublished(updatedPublishedValue)
    } catch (e) {
      addErrorNotification({
        label: 'Failed to publish listing',
        description: e.message,
        updateToastId: progressToastId,
      })
      return
    }
  }

  return (
    <Section>
      <SectionHeader hr2 h3="Features" />
      <UserCard
        key="live"
        svg={LargeDocument}
        primaryText="Make an offer"
        secondaryText="Enable the ‘Make an offer’ button on this listing."
        trailingItems={
          <Fragment>
            <ToggleSwitch
              onChange={updateListingPublishing}
              checked={listingPublished || false}
            />
          </Fragment>
        }
      />
    </Section>
  )
}

ListingPublish.fragments = {
  Listing: gql`
    fragment ListingPublish_Listing on Listing {
      id
      published
      documents {
        name
      }
    }
  `,
}

export const UPDATE_LISTING_PUBLISH: TypedDocumentNode<
  UpdateListingPublishMutation,
  UpdateListingPublishMutationVariables
> = gql`
  mutation UpdateListingPublishMutation($input: UpdateListingInput!) {
    result: updateListing(input: $input) {
      listing {
        id
        published
      }
    }
  }
`
