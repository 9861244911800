/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, StackMain } from '@propps/ui'
import styled from '@emotion/styled'
import React from 'react'
import { platformAuthCreate, Role, useAPIClient, useAuth } from '@propps/client'
import { useHistory } from 'react-router-dom'

const CtaContainer = styled.div`
  text-align: center;
`

export const PlatformHint = ({ to }: { to: Record<Role, string> }) => {
  const client = useAPIClient()
  const history = useHistory()
  const auth = useAuth()

  const next = () => {
    if (!auth?.role) {
      history.replace('/auth/register')
      return
    }
    history.replace(to[auth?.role?.name])
  }

  const onProceed = async () => {
    await platformAuthCreate(client)
    next()
  }

  const onDecline = () => {
    next()
  }
  return (
    <StackMain variant="topCenter">
      <h2>
        Would you like to sign in faster next time with Touch ID or Face ID?
      </h2>

      <p className="grey">
        By enabling Touch ID or Face ID, you can skip phone number verification
        and sign in faster.
      </p>

      <CtaContainer>
        <Button cta onClick={onProceed}>
          Ok
        </Button>
        <p>
          <a href="#" onClick={onDecline}>
            No thanks
          </a>
        </p>
      </CtaContainer>
    </StackMain>
  )
}
