import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import {
  AppCard,
  Button,
  Cards,
  FixedButtonWrapper,
  StackMain,
} from '@propps/ui'
import React from 'react'
import { match as Match, useHistory } from 'react-router-dom'

import { ListDeveloperAppsQuery } from './__generated__/ListDeveloperAppsQuery'

const LIST_DEVELOPER_APPS_QUERY: TypedDocumentNode<ListDeveloperAppsQuery> = gql`
  query ListDeveloperAppsQuery {
    apps: developerApps {
      id
      name
    }
  }
`

export function DeveloperAppsView({ match }: { match: Match<any> }) {
  const history = useHistory()

  const { data } = useQuery(LIST_DEVELOPER_APPS_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const goToAgency = (agencyId: string) => {
    history.push(`${match.url}/${agencyId}`)
  }

  return (
    <>
      <StackMain variant="app">
        <h1>Developer apps</h1>
        <Cards grid>
          {data?.apps ? (
            data.apps.map((app, index) => {
              return (
                <AppCard
                  key={app.id}
                  title={app.name}
                  id={app.id}
                  status="Enabled"
                  onClick={() => goToAgency(app.id)}
                />
              )
            })
          ) : (
            <FixedButtonWrapper>
              <Button cta pending />
            </FixedButtonWrapper>
          )}
        </Cards>
      </StackMain>
      <FixedButtonWrapper>
        <Button onClick={() => history.push(match.path + '/create')} cta>
          Create new app
        </Button>
      </FixedButtonWrapper>
    </>
  )
}
