import React, { Component, Fragment } from 'react'
import { ErrorPage } from './error-page'
import { ErrorReporting } from '@propps/client'

export type ErrorBoundaryProps = {
  children?: React.ReactNode
}

export class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: { error?: unknown }

  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      error: null,
    }
  }

  componentDidCatch(err: any) {
    if (process.env.NODE_ENV !== 'production') {
      throw err
    }

    ErrorReporting.report(err)
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPage
          title={
            <Fragment>
              Oh, bugger
              <br />
              <span className="light">Something went wrong.</span>
            </Fragment>
          }
          errorMessage={
            <Fragment>
              To be rather frank, we have no idea what just happened. It's not
              great, but it's been reported. Maybe refresh the page and try your
              thing again.
            </Fragment>
          }
        />
      )
    }

    return <Fragment>{this.props.children}</Fragment>
  }
}
