// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { GetAllOffers, useQuery } from '@propps/client'
import { Cards, OfferCard } from '@propps/ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { MatchProps } from '../../util/match-props'

import {
  compareOffers,
  findHighestOfferAmount,
  findPrimaryBuyer,
  isConditionalOffer,
} from '../../util/offer-utils'
import { OfferEmpty } from './empty'

export interface OffersViewProps extends MatchProps {
  listingId: string
}

export function OffersView({ listingId, match }: OffersViewProps) {
  const history = useHistory()
  const { data } = useQuery([GetAllOffers, { listingId: listingId }], {
    static: true,
  })

  const offers = data
    ?.filter((offer) => offer.buyers && offer.created)
    .sort((a, b) => compareOffers(a, b)) // Filters offers based on being active and their amounts

  const highestOfferAmount = findHighestOfferAmount(offers ?? [])

  const goToOffer = (offerId: string) => {
    history.push(`/offers/${offerId}`)
  }

  // loading state
  if (!offers) {
    return null
  }

  if (!offers.length) {
    return <OfferEmpty match={match} />
  }

  return (
    <Cards grid>
      {offers.map((offer, index) => {
        const primaryBuyer = findPrimaryBuyer(offer)
        return (
          primaryBuyer && (
            <OfferCard
              fullName={primaryBuyer.name}
              amount={offer.offer.amount}
              timestamp={offer.created}
              identity={primaryBuyer.verificationStatus === 'verified'}
              finance={['preapproved', 'not-applicable'].includes(
                offer.offer.finance.status
              )}
              conditional={isConditionalOffer(offer)}
              expiry={offer.offer.expiry}
              leadingOffer={highestOfferAmount === Number(offer.offer.amount)}
              multipleBuyers={offer.buyers.signatories.length > 1}
              key={`offer-card-${index}`}
              onClick={() => goToOffer(offer.id)}
            />
          )
        )
      })}
    </Cards>
  )
}
