import { HeroCross, Icon, StackMain } from '@propps/ui'
import React from 'react'
import { Link } from 'react-router-dom'
import { H2 } from '../pages/error/error'

export function ErrorPage({
  title,
  errorMessage,
  showHomeButton,
}: {
  title: React.ReactNode
  errorMessage: React.ReactNode
  showHomeButton?: boolean
}) {
  return (
    <StackMain variant="topCenter">
      <Icon svg={HeroCross} size={128} />
      <H2>{title}</H2>
      <p>{errorMessage}</p>
      {showHomeButton && <Link to="/">Home</Link>}
    </StackMain>
  )
}
