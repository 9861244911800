import { gql, TypedDocumentNode, useMutation } from '@apollo/client'
import {
  AddressMetaData,
  getErrors,
  Listing,
  parseAddress,
  useAddressFinderWidget,
} from '@propps/client'
import {
  Button,
  Input,
  Label,
  ListItem,
  ListWrapper,
  ReadOnly,
  Section,
  SectionHeader,
} from '@propps/ui'
import { useFormik } from 'formik'
import { omit } from 'ramda'
import React, { useRef } from 'react'

import { useToast } from '../../../../components/toast'
import { formatAddress } from '../../../../util/offer-utils'

import { ListingDetails_Listing } from './__generated__/ListingDetails_Listing'
import {
  UpdateListingDetailsMutation,
  UpdateListingDetailsMutationVariables,
} from './__generated__/UpdateListingDetailsMutation'

const emptyAddress = {
  line1: '',
  city: '',
  state: '',
  postcode: '',
}

export function ListingDetails({
  listing,
}: {
  listing: ListingDetails_Listing
}) {
  const [update] = useMutation(UPDATE_LISTING_DETAILS)

  const {
    addInfoNotification,
    addSuccessNotification,
    addErrorNotification,
  } = useToast()

  const formik = useFormik({
    initialValues: {
      id: listing.id,
      url: listing.url,
      appId: listing.source.appId,
      property: {
        address: emptyAddress,
        fullAddress: formatAddress((listing as unknown) as Listing),
      },
    },
    onSubmit: async (values) => {
      const progressToastId = addInfoNotification({
        label: 'Settings updating...',
        progress: true,
      })
      try {
        const urlChanged = listing.url !== formik.values.url
        let address = formik.values.property.address
        if (
          formatAddress((listing as unknown) as Listing) ===
          formik.values.property.fullAddress
        ) {
          // address not changed
          address = listing.property.address
        } else if (!formik.values.property.address.line1) {
          const parsedAddress = parseAddress(formik.values.property.fullAddress)
          address = parsedAddress ?? listing.property.address
        }

        const fetchResult = await update({
          variables: {
            input: {
              id: listing.id,
              url: formik.values.url,
              property: {
                address: omit(['__typename'])(address),
              },
            },
          },
        })
        if (fetchResult.errors) {
          addErrorNotification({
            label: 'Settings update error',
            description: fetchResult.errors[0].message,
            updateToastId: progressToastId,
          })
          return
        }
        addSuccessNotification({
          label: 'Settings updated',
          description: 'Settings updated successfully',
          updateToastId: progressToastId,
        })
        if (urlChanged) {
          addSuccessNotification({
            label: 'QR Code updated',
            description: 'QR code with new url updated successfully',
          })
        }
        formik.setFieldValue(`property.address`, emptyAddress)
      } catch (e) {
        console.log(e)
        addErrorNotification({
          label: 'Settings update error',
          description: e.message,
          updateToastId: progressToastId,
        })
        return
      }
    },
  })

  const addressInput = useRef<HTMLElement>()
  useAddressFinderWidget(addressInput, {
    onSelect: (fullAddress: string, metadata: AddressMetaData) => {
      const property = {
        address: {
          line1: [metadata.address_line_1, metadata.address_line_2]
            .filter((value: string) => value && value.trim().length > 0)
            .join(', '),
          city: metadata.locality_name,
          state: metadata.state_territory,
          postcode: metadata.postcode,
        },
        fullAddress: `${metadata.address_line_1}, ${
          metadata.locality_name ?? ''
        }, ${metadata.state_territory ?? ''} ${metadata.postcode ?? ''}`,
      }
      formik.setFieldValue(`property`, property)
      formik.setFieldValue(`property.fullAddress`, fullAddress)
    },
  })

  return (
    <Section>
      <SectionHeader hr h3="Listing details" />
      <form onSubmit={formik.handleSubmit}>
        <ListWrapper>
          <ListItem>
            <Label size="small" color="grey">
              Listing CRM ID
            </Label>
            <ReadOnly>{listing.source.foreignId}</ReadOnly>
          </ListItem>
          <ListItem>
            <Label size="small" color="grey">
              URL
            </Label>
            <Input
              type="textarea"
              placeholder="..."
              {...formik.getFieldProps(`url`)}
              errors={getErrors(formik, `url`)}
            />
          </ListItem>
          <ListItem>
            <Label size="small" color="grey">
              Address
            </Label>
            <Input
              type="textarea"
              {...formik.getFieldProps(`property.fullAddress`)}
              errors={getErrors(formik, `property.fullAddress`)}
              ref={addressInput as React.RefObject<HTMLInputElement>}
            />
          </ListItem>
        </ListWrapper>
        <Button type="submit">Save changes</Button>
      </form>
    </Section>
  )
}

ListingDetails.fragments = {
  Listing: gql`
    fragment ListingDetails_Listing on Listing {
      id
      url
      source {
        appId
        foreignId
      }
      property {
        address {
          line1
          city
          state
          postcode
        }
      }
    }
  `,
}

export const UPDATE_LISTING_DETAILS: TypedDocumentNode<
  UpdateListingDetailsMutation,
  UpdateListingDetailsMutationVariables
> = gql`
  mutation UpdateListingDetailsMutation($input: UpdateListingInput!) {
    result: updateListing(input: $input) {
      listing {
        id
        url
        property {
          address {
            line1
            city
            state
            postcode
          }
        }
      }
    }
  }
`
