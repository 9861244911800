import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import {
  Button,
  Flex,
  FlexItem,
  ListItem,
  ListWrapper,
  ReadOnly,
  Section,
  SectionHeader,
  StackMain,
  ThumbCard,
} from '@propps/ui'
import { format, parseISO } from 'date-fns'
import React from 'react'
import { match as Match } from 'react-router-dom'

import { PageNotFound } from '../error'
import {
  DeveloperAppQuery,
  DeveloperAppQueryVariables,
} from './__generated__/DeveloperAppQuery'

const DEVELOPER_APP_QUERY: TypedDocumentNode<
  DeveloperAppQuery,
  DeveloperAppQueryVariables
> = gql`
  query DeveloperAppQuery($id: ID!) {
    developerApp(id: $id) {
      id
      name
      createdAt
    }
  }
`

export function DeveloperApp({ match }: { match: Match<{ appId: string }> }) {
  const { data } = useQuery(DEVELOPER_APP_QUERY, {
    variables: { id: match.params.appId },
    fetchPolicy: 'cache-and-network',
  })

  if (data === undefined) return <StackMain variant="app"></StackMain>

  if (!data.developerApp) {
    return <PageNotFound />
  }

  return (
    <StackMain variant="app">
      <h1>{data.developerApp.name}</h1>
      <Section>
        <SectionHeader hr h3="Details" />
        <ListWrapper>
          <ListItem>
            <label>App ID</label>
            <ReadOnly className="code">{data.developerApp.id}</ReadOnly>
          </ListItem>
          <ListItem>
            <label>Name</label>
            <ReadOnly>{data.developerApp.name}</ReadOnly>
          </ListItem>
          <ListItem>
            <label>Created</label>
            <ReadOnly>
              {format(parseISO(data.developerApp.createdAt), 'dd/MM/yyyy')}
            </ReadOnly>
          </ListItem>
        </ListWrapper>
      </Section>
      <Section>
        <SectionHeader hr2 h3="Linked agencies (to do)" />
        <Flex>
          <FlexItem xs={{ size: 6, display: 'flex' }} md={{ size: 3 }}>
            <ThumbCard title="Agency name" desc="14 listings" key="1" />
          </FlexItem>
        </Flex>
      </Section>
      <Section>
        <SectionHeader hr2 h3="Danger zone (to do)" />
        <Button danger>Delete this app</Button>
        <Button>Disable this app</Button>
        <p>
          Deleting this developer app will immediately disconnect all listings
          and prevent Propps from working on all currently connected listings.
          This cannot be undone.
        </p>
      </Section>
    </StackMain>
  )
}
