import { Button } from '@propps/ui'
import React from 'react'
import { Hero } from '../../components/hero'

import { useHistory } from 'react-router-dom'
import { MatchProps } from '../../util/match-props'

export interface OfferEmptyProps extends MatchProps {}

export function OfferEmpty({ match }: OfferEmptyProps) {
  const history = useHistory()

  // FIXME: maybe go somewhere else?
  const handleClick = () => history.push(match.url + `/campaign`)

  return (
    <Hero title="No Offers">
      <p>Do buyers know they can make an offer from your webiste?</p>

      <Button cta onClick={handleClick}>
        Get the word out
      </Button>
    </Hero>
  )
}
