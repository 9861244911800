import { gql, TypedDocumentNode, useQuery } from '@apollo/client'
import React, { Fragment } from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'

import { TermsRevisionView } from './revision'
import {
  CurrentTermsQuery,
  CurrentTermsQueryVariables,
} from './__generated__/CurrentTermsQuery'
import {
  TermsRevisionQuery,
  TermsRevisionQueryVariables,
} from './__generated__/TermsRevisionQuery'

export function TermsLineRoot({
  match,
}: {
  match: Match<{ lineName: string }>
}) {
  return (
    <Switch>
      <Route path={match.path} exact>
        {({ match }) => <TermsLineRoute match={match!} />}
      </Route>
      <Route path={`${match.path}/:revisionId`}>
        {({ match }) => <TermsRevisionRoute match={match!} />}
      </Route>
    </Switch>
  )
}

function TermsLineRoute({ match }: { match: Match<{ lineName: string }> }) {
  const { data } = useQuery(CURRENT_TERMS_QUERY, {
    variables: { lineName: match.params.lineName },
  })

  return (
    <Fragment>
      {data?.line ? <TermsRevisionView revision={data.line?.revision} /> : null}
    </Fragment>
  )
}

function TermsRevisionRoute({
  match,
}: {
  match: Match<{ lineName: string; revisionId: string }>
}) {
  const { data } = useQuery(TERMS_REVISION_QUERY, {
    variables: {
      lineName: match.params.lineName,
      revisionId: match.params.revisionId,
    },
  })

  return (
    <Fragment>
      {data?.line?.revision ? (
        <TermsRevisionView revision={data.line?.revision} />
      ) : null}
    </Fragment>
  )
}

const CURRENT_TERMS_QUERY: TypedDocumentNode<
  CurrentTermsQuery,
  CurrentTermsQueryVariables
> = gql`
  query CurrentTermsQuery($lineName: String!) {
    line: termsLine(name: $lineName) {
      revision: currentRevision {
        ...TermsRevisionView_TermsRevision
      }
    }
  }
  ${TermsRevisionView.fragments.TermsRevision}
`

const TERMS_REVISION_QUERY: TypedDocumentNode<
  TermsRevisionQuery,
  TermsRevisionQueryVariables
> = gql`
  query TermsRevisionQuery($lineName: String!, $revisionId: ID!) {
    line: termsLine(name: $lineName) {
      revision(id: $revisionId) {
        ...TermsRevisionView_TermsRevision
      }
    }
  }
  ${TermsRevisionView.fragments.TermsRevision}
`
