import { gql } from '@apollo/client'
import {
  Button,
  CircleMinus,
  CirclePlus2,
  Flex,
  FlexItem,
  Icon,
  LargeUser,
  Plus,
  SectionHeader,
  StackModal,
  UserCard,
  useStackModalState,
} from '@propps/ui'
import React, { Fragment, useEffect, useState } from 'react'
import { match as Match, useHistory, useRouteMatch } from 'react-router-dom'

import { useToast } from '../../../../components/toast'
import { ListingAgentsInviteForm } from './invite-form'
import {
  ListingAgents_Listing,
  ListingAgents_Listing_agents,
} from './__generated__/ListingAgents_Listing'

export interface ListingAgentsProps {
  match: Match
  listing: ListingAgents_Listing
}

export function ListingAgents({ match, listing }: ListingAgentsProps) {
  const history = useHistory()
  const toast = useToast()

  const inviteAgentModal = useStackModalState()
  const assignAgentModal = useStackModalState()
  const removeAgentModal = useStackModalState()

  const routeMatch = useRouteMatch<{ param: string }>(match.path + '/:param')

  const [state, setState] = useState({
    isEdit: false,
    // this is an idea to use state instead of router /remove/:agentid
    // I can canche it later
    removeModalAgent: undefined as ListingAgents_Listing_agents | undefined,
  })

  const [assignableAgents, setAssignableAgents] = useState<
    ListingAgents_Listing_agents[]
  >([
    {
      __typename: 'AgentProfile',
      email: 'agent@example.com',
      phone: '+61 123 123 123',
      name: 'Some Agent',
    },
  ])

  const handleToggleEdit = () => {
    setState((prev) => ({ ...prev, isEdit: !prev.isEdit }))
  }

  const handleRemoveAgentPrompt = (
    agent: ListingAgents_Listing_agents
  ) => () => {
    removeAgentModal.show()
    setState((prev) => ({ ...prev, removeModalAgent: agent }))
  }

  const handleRemoveAgent = (
    agent: ListingAgents_Listing_agents
  ) => async () => {
    removeAgentModal.hide()

    const progressToastId = toast.addInfoNotification({
      label: 'Removing agent from listing...',
      progress: true,
    })

    // call mutation here

    setTimeout(() => {
      toast.addSuccessNotification({
        label: 'Agent removed from listing',
        updateToastId: progressToastId,
      })
    }, 1000)
  }

  const handleAssignAgent = (
    agent: ListingAgents_Listing_agents
  ) => async () => {
    const progressToastId = toast.addInfoNotification({
      label: 'Adding agent to listing...',
      progress: true,
    })

    // TODO: use add mutation
    setTimeout(() => {
      toast.addSuccessNotification({
        label: 'Agent added to listing',
        updateToastId: progressToastId,
      })
    }, 1000)

    // TODO: apollo update cache

    // TODO: fix tu use ids
    setAssignableAgents((prev) => prev.filter((el) => el.email !== agent.email))
  }

  // sync route to agent connect modal
  useEffect(() => {
    if (routeMatch && routeMatch.params.param === 'assign') {
      assignAgentModal.show()
    } else {
      assignAgentModal.hide()
    }
  }, [routeMatch, assignAgentModal])

  // sync route to agent invite modal
  useEffect(() => {
    if (routeMatch && routeMatch.params.param === 'invite') {
      inviteAgentModal.show()
    } else {
      inviteAgentModal.hide()
    }
  }, [routeMatch, inviteAgentModal])

  return (
    <Fragment>
      <SectionHeader hr2 h3="Assigned Agents">
        <Button small onClick={handleToggleEdit}>
          {state.isEdit ? 'Done' : 'Edit'}
        </Button>
      </SectionHeader>

      <Flex>
        <FlexItem xs={{ size: 6, display: 'flex' }} md={{ size: 3 }} key="add">
          <UserCard
            variant="dashed"
            svg={Plus}
            primaryText="Assign an agent"
            secondaryText="To view or manage offers"
            onClick={() => history.push(match.url + '/assign')}
          />
        </FlexItem>

        {listing.agents?.map((agent) => {
          return (
            <FlexItem
              xs={{ size: 6, display: 'flex' }}
              md={{ size: 3 }}
              key={agent.email}
            >
              <UserCard
                variant={undefined /* TODO: make dashed for invited */}
                svg={LargeUser}
                primaryText={agent.name}
                secondaryText={
                  <Fragment>
                    {agent.email}
                    <br />
                    {agent.phone}
                  </Fragment>
                }
                clickable={!state.isEdit}
                onClick={() => {
                  if (!state.isEdit) {
                    // TODO: enable when agent id will be known
                    // history.push(`/agents/${id}`)
                  }
                }}
                trailingItems={
                  state.isEdit && (
                    <Icon
                      svg={CircleMinus}
                      size={24}
                      cursor="pointer"
                      tabIndex={0}
                      onClick={handleRemoveAgentPrompt(agent)}
                    />
                  )
                }
              />
            </FlexItem>
          )
        })}
      </Flex>

      <StackModal
        size="l"
        state={{ ...inviteAgentModal, hide: () => history.replace(match.url) }}
        title="Invite an agent"
        centered
      >
        {/* FIXME: support multiple agencies!! */}
        {!!listing.agencies?.length ? (
          <ListingAgentsInviteForm
            agency={listing.agencies[0]}
            onSuccess={() => history.push(match.url)}
            onError={() => history.push(match.url)}
          />
        ) : (
          <p>No agency connected</p>
        )}
      </StackModal>

      <StackModal
        size="l"
        state={{ ...assignAgentModal, hide: () => history.replace(match.url) }}
        title="Assign an agent"
        centered
      >
        <Flex>
          <UserCard
            variant="dashed"
            svg={CirclePlus2}
            primaryText="Invite a new agent"
            secondaryText="Invite a new agent to Propps."
            onClick={() => history.push(match.url + '/invite')}
          />

          {assignableAgents.map((agent) => (
            <FlexItem xs={{ size: 6, display: 'flex' }} key={agent.email}>
              <UserCard
                variant={undefined /* TODO: make dashed for invited */}
                svg={LargeUser}
                primaryText={agent.name}
                secondaryText={
                  <Fragment>
                    {agent.email}
                    <br />
                    {agent.phone}
                  </Fragment>
                }
                clickable
                onClick={handleAssignAgent(agent)}
              />
            </FlexItem>
          ))}
        </Flex>
      </StackModal>

      <StackModal
        size="l"
        state={{
          ...removeAgentModal,
          hide: () => removeAgentModal.hide(),
        }}
        title="Remove agent"
        centered
        cta
      >
        <p>
          Are you sure you want to remove{' '}
          <strong>{state.removeModalAgent?.name}</strong> from this listing?
          They will no longer receive notifications of offers for this property
        </p>

        <Button cta onClick={handleRemoveAgent(state.removeModalAgent!)}>
          Remove agent
        </Button>
      </StackModal>
    </Fragment>
  )
}

ListingAgents.fragments = {
  Listing: gql`
    fragment ListingAgents_Listing on Listing {
      id
      agents {
        phone
        email
        name
      }
      agencies {
        ...ListingAgentsInviteForm_Agency
      }
    }

    ${ListingAgentsInviteForm.fragments.Agency}
  `,
}
