/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Button } from '@propps/ui'
import { AnimatePresence, motion } from 'framer-motion'
import { complement } from 'ramda'
import { Children, cloneElement } from 'react'
import * as ReactIs from 'react-is'

export function SoloFormLayout({ children }: { children?: React.ReactNode }) {
  const button = Children.toArray(children).find(isPrimaryAction) ?? null
  children = Children.toArray(children).filter(complement(isPrimaryAction))

  const key = getKey(children)

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <AnimatePresence
        exitBeforeEnter
        onExitComplete={() => {
          window.scrollTo(0, 0)
        }}
      >
        {children && (
          <Transition key={key}>
            <div css={{ flexGrow: 1 }}>{children}</div>
            <NormalButtonWrapper css={{ marginBottom: '-56px' }}>
              {button && cloneElement(button, { internal_isChild: true })}
            </NormalButtonWrapper>
          </Transition>
        )}
      </AnimatePresence>
    </div>
  )
}

function PrimaryAction({
  internal_isChild,
  ...props
}: Omit<React.ComponentProps<typeof Button>, 'cta'> & {
  internal_isChild?: boolean
}) {
  if (process.env.NODE_ENV !== 'production') {
    if (!internal_isChild) {
      throw new Error(
        '<FrameContentLayout.Button> must only be rendered as a direct child of <FrameContentLayout>'
      )
    }
  }

  return <Button cta {...props} />
}

const isPrimaryAction = (
  child: React.ReactNode
): child is React.ReactElement<React.ComponentProps<typeof PrimaryAction>> =>
  ReactIs.isElement(child) && child.type === PrimaryAction

SoloFormLayout.PrimaryAction = PrimaryAction

function Transition({
  children,
  ...rest
}: Omit<
  React.ComponentProps<typeof motion.div>,
  'initial' | 'animate' | 'exit' | 'transition'
>) {
  return (
    <motion.div
      initial={{ y: 64, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -64, opacity: 0 }}
      transition={{ type: 'spring', damping: 40, stiffness: 400 }}
      css={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      {...rest}
    >
      {children}
    </motion.div>
  )
}

function NormalButtonWrapper({
  children,
  ...rest
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div css={{ display: 'flex', justifyContent: 'center' }} {...rest}>
      {children}
    </div>
  )
}

function getKey(node: React.ReactNode): string | number | undefined {
  if (Children.count(node) !== 1) {
    return undefined
  }

  node = Children.only(Array.isArray(node) ? node[0] : node)

  if (ReactIs.isElement(node) || ReactIs.isFragment(node)) {
    return node.key || undefined
  }

  return undefined
}
