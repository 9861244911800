import React from 'react'
import { gql, TypedDocumentNode, useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import { getErrors } from '@propps/client'
import {
  Button,
  FixedButtonWrapper,
  Input,
  Label,
  ListItem,
  ListWrapper,
} from '@propps/ui'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useToast } from '../../../../components/toast'
import {
  InviteAgentMutation,
  InviteAgentMutationVariables,
} from './__generated__/InviteAgentMutation'
import { CreateAgentInput } from '../../../../__generated__/apollo-global'
import { ListingAgentsInviteForm_Agency } from './__generated__/ListingAgentsInviteForm_Agency'
import { ListingAgentsInviteForm_Agent } from './__generated__/ListingAgentsInviteForm_Agent'

export interface ListingAgentsInviteFormProps {
  agency: ListingAgentsInviteForm_Agency
  onSuccess: (agent: ListingAgentsInviteForm_Agent) => void
  onError: () => void
}

/**
 * Form for inviting agent directly to listing
 */
export function ListingAgentsInviteForm({
  onSuccess,
  onError,
  agency,
}: ListingAgentsInviteFormProps) {
  const {
    addInfoNotification,
    addSuccessNotification,
    addErrorNotification,
  } = useToast()

  const [invite] = useMutation(INVITE_AGENT_MUTATION, {
    // this disable throwing
    onError: () => {
      onError()
    },
    update: (cache, result) => {
      if (!result.data) return

      // update agency
      cache.modify({
        id: cache.identify({ __typename: 'Agency', id: agency.id }),
        fields: {
          agents: (value) => {
            if (!(value && Array.isArray(value))) return value

            const ref = cache.writeFragment({
              id: cache.identify({
                __typename: 'Agent',
                id: result.data!.result.agent.id,
              }),
              fragment: ListingAgentsInviteForm.fragments.Agent,
              data: result.data!.result.agent,
            })

            return [...value, ref]
          },
        },
      })
    },
  })

  const formik = useFormik<{
    agent: CreateAgentInput
  }>({
    initialValues: {
      agent: {
        agencyId: agency.id,
        email: '',
        firstName: '',
        lastName: '',
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      const progressToastId = addInfoNotification({
        label: 'Inviting agent to listing...',
        progress: true,
      })

      const result = await invite({
        variables: {
          input: {
            agencyId: agency.id,
            firstName: values.agent.firstName,
            lastName: values.agent.lastName,
            email: values.agent.email,
          },
        },
      })

      if (result.errors) {
        addErrorNotification({
          label: 'Inviting agent to listing error',
          description: result.errors[0].message,
          updateToastId: progressToastId,
        })
        return
      } else {
        addSuccessNotification({
          label: 'Agent created',
          description: 'Agent invited to listing',
          updateToastId: progressToastId,
        })
      }

      helpers.resetForm()
      onSuccess(result.data!.result.agent)
    },
  })

  return (
    <form
      onSubmit={formik.handleSubmit}
      css={css`
        width: 100%;
      `}
    >
      <ListWrapper>
        <ListItem>
          <Label>First name</Label>
          <Input
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`agent.firstName`)}
            errors={getErrors(formik, 'agent.firstName')}
          />
        </ListItem>
        <ListItem>
          <Label>Last name</Label>
          <Input
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`agent.lastName`)}
            errors={getErrors(formik, 'agent.lastName')}
          />
        </ListItem>
        <ListItem>
          <Label>Email</Label>
          <Input
            type="text"
            placeholder="..."
            {...formik.getFieldProps(`agent.email`)}
            errors={getErrors(formik, `agent.email`)}
            inputMode="email"
          />
        </ListItem>
      </ListWrapper>

      <p>Agent will be able to view all listings and offer fot the agency</p>

      <FixedButtonWrapper position="inherit">
        <Button cta type="submit" disabled={formik.isSubmitting}>
          {formik.isSubmitting ? 'Pending...' : 'Add agent'}
        </Button>
      </FixedButtonWrapper>
    </form>
  )
}

ListingAgentsInviteForm.fragments = {
  Agent: gql`
    fragment ListingAgentsInviteForm_Agent on Agent {
      id
      phone
      email
      firstName
      lastName
    }
  `,
  Agency: gql`
    fragment ListingAgentsInviteForm_Agency on Agency {
      id
    }
  `,
}

// TODO: change to assign agent to lsiting mutation
const INVITE_AGENT_MUTATION: TypedDocumentNode<
  InviteAgentMutation,
  InviteAgentMutationVariables
> = gql`
  mutation InviteAgentMutation($input: CreateAgentInput!) {
    result: createAgent(input: $input) {
      agent {
        ...ListingAgentsInviteForm_Agent
      }
    }
  }
  ${ListingAgentsInviteForm.fragments.Agent}
`

const validationSchema = Yup.object({
  agent: Yup.object({
    email: Yup.string().trim().required().label('Email'),
    firstName: Yup.string().trim().required().label('First name'),
    lastName: Yup.string().required().label('Last name'),
  }),
})
