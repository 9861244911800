import {
  gql,
  TypedDocumentNode,
  useApolloClient,
  useQuery,
} from '@apollo/client'
import { Role, useAuth } from '@propps/client'
import { color, MenuItem, MenuItemUser, StackContext } from '@propps/ui'
import { Icon, ArrowOutLeft } from '@propps/ui'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { MenuQuery } from './__generated__/MenuQuery'

const Menu = () => {
  const auth = useAuth()
  const history = useHistory()
  const stacks = useContext(StackContext)
  const client = useApolloClient()

  const { data, refetch } = useQuery(MENU_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  // refetch the user when auth says the user has changed in some way
  useEffect(() => {
    refetch()
  }, [auth.uid, auth.role, refetch])

  function signOut() {
    auth.signOut().then(() => {
      client.cache.modify({
        fields: {
          me: (value, { DELETE }) => DELETE,
        },
      })
    })

    stacks.toggleNav()
    history.push('/')
  }

  const user = data?.me

  return (
    <>
      {/* signed-in menu */}
      {auth.uid && (
        <>
          {user && (
            <MenuItemUser
              avatar={
                user
                  ? `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`
                  : ''
              }
              name={
                user ? `${user.firstName ?? ''} ${user.lastName ?? ''}` : ''
              }
              email={user.email}
              href="/"
            />
          )}
          <hr />
          {/* admin only menu */}
          {auth.role?.name === Role.ADMIN && (
            <>
              <MenuItem to="/apps" onClick={() => stacks.toggleNav()}>
                Developer Apps
              </MenuItem>
              <MenuItem to="/agencies" onClick={() => stacks.toggleNav()}>
                Agencies
              </MenuItem>
              <MenuItem to="/listings" onClick={() => stacks.toggleNav()}>
                Listings
              </MenuItem>
              <MenuItem
                href="https://docs.propps.com"
                target="_blank"
                onClick={() => stacks.toggleNav()}
              >
                Docs
              </MenuItem>
              <MenuItem to="/settings" onClick={() => stacks.toggleNav()}>
                Settings
              </MenuItem>
              <hr />
            </>
          )}
          <MenuItem href="#" onClick={() => signOut()}>
            <Icon svg={ArrowOutLeft} size={24} fill={color.grey} />
            Sign out
          </MenuItem>
          <hr />
        </>
      )}
      <MenuItem small href="/help" onClick={() => stacks.toggleNav()}>
        Help
      </MenuItem>
      <MenuItem small href="/contact-us" onClick={() => stacks.toggleNav()}>
        Contact us
      </MenuItem>
      <MenuItem small href="/terms" onClick={() => stacks.toggleNav()}>
        Terms of use
      </MenuItem>
      <MenuItem small href="/help" onClick={() => stacks.toggleNav()}>
        Privacy
      </MenuItem>
    </>
  )
}

export default Menu

const MENU_QUERY: TypedDocumentNode<MenuQuery> = gql`
  query MenuQuery {
    me {
      uid
      firstName
      lastName
      email
    }
  }
`
