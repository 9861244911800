import { gql } from '@apollo/client'
import React, { Fragment } from 'react'
import { ListingCampaignAssets } from './listing-campaign-assets'
import { ListingCampaign_Listing } from './__generated__/ListingCampaign_Listing'

export function ListingCampaign({
  listing,
}: {
  listing: ListingCampaign_Listing
}) {
  return (
    <Fragment>
      <ListingCampaignAssets listing={listing} />
    </Fragment>
  )
}

ListingCampaign.fragments = {
  Listing: gql`
    fragment ListingCampaign_Listing on Listing {
      id
      ...ListingCampaignAssets_Listing
    }
    ${ListingCampaignAssets.fragments.Listing}
  `,
}
