import { gql } from '@apollo/client'
import React, { Fragment } from 'react'

import { ListingDetails } from './listing-details'
import { ListingDocs } from './listing-docs'
import { ListingPriceGuide } from './listing-price-guide'
import { ListingPublish } from './listing-publish'
import { ListingSettings_Listing } from './__generated__/ListingSettings_Listing'

export function ListingSettings({
  listing,
}: {
  listing: ListingSettings_Listing
}) {
  return (
    <Fragment>
      <ListingPublish listing={listing} />
      <ListingDetails listing={listing} />
      <ListingDocs listing={listing} />
      <ListingPriceGuide listing={listing} />
    </Fragment>
  )
}

ListingSettings.fragments = {
  Listing: gql`
    fragment ListingSettings_Listing on Listing {
      id
      ...ListingPublish_Listing
      ...ListingDetails_Listing
      ...ListingDocs_Listing
      ...ListingPriceGuide_Listing
    }
    ${ListingPublish.fragments.Listing}
    ${ListingDetails.fragments.Listing}
    ${ListingDocs.fragments.Listing}
    ${ListingPriceGuide.fragments.Listing}
  `,
}
