import {
  gql,
  TypedDocumentNode,
  useApolloClient,
  useMutation,
} from '@apollo/client'
import styled from '@emotion/styled'
import {
  ArrowOutUpRight,
  Button,
  Flex,
  FlexItem,
  Icon,
  Label,
  screen,
  Section,
  SectionHeader,
  size,
  ThumbCard,
} from '@propps/ui'
import { isAfter, parseISO } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { useToast } from '../../../../components/toast'
import QrA4Background from '../../../../images/qr-a4.jpg'
import QrMobileBackground from '../../../../images/qr-mobile.jpg'
import QrTabletBackground from '../../../../images/qr-tablet.jpg'

import {
  GenerateCampaignAssetsMutation,
  GenerateCampaignAssetsMutationVariables,
} from './__generated__/GenerateCampaignAssetsMutation'
import { ListingCampaignAssets_Listing } from './__generated__/ListingCampaignAssets_Listing'

const campaignAssetTypes = [
  {
    name: 'QR-MOBILE',
    title: 'Mobile QR',
    desc: 'Let buyers scan the code from your mobile phone.',
    image: QrMobileBackground,
    ext: 'png',
  },
  {
    name: 'QR-A4',
    title: 'A4 display stand',
    desc: 'Let buyers scan the code at property inspections.',
    image: QrA4Background,
    ext: 'pdf',
  },
  {
    name: 'QR-TABLET',
    title: 'Tablet QR',
    desc: 'Let buyers scan the code from your iPad or other tablet device.',
    image: QrTabletBackground,
    ext: 'png',
  },
]

const QrWrapper = styled.div<{ list?: boolean }>`
  margin: ${size(2)} 0 ${size(2)};

  @media ${screen.l.up} {
    margin: ${size(4)} 0 ${size(6)};
  }
`

const ThumbCardWrapper = styled.a`
  color: inherit;
  text-decoration: none;
`

export function ListingCampaignAssets({
  listing,
}: {
  listing: ListingCampaignAssets_Listing
}) {
  const [update] = useMutation(GENERATE_CAMPAIGN_ASSETS, {
    variables: { input: listing.id },
    update: (cache) => {
      // empty the listing's campaignAssets list, as they should have been dumped
      // for regeneration
      cache.modify({
        id: apollo.cache.identify({ __typename: 'Listing', id: listing.id }),
        fields: {
          campaignAssets: (value) => [],
        },
      })
    },
  })

  const apollo = useApolloClient()
  const [waiting, setWaiting] = useState(false)
  const [assetLastGeneratedTime, setAssetLastGeneratedTime] = useState<
    string | null
  >(null)
  const progressToastId = useRef<string | null>(null)

  const {
    addInfoNotification,
    addSuccessNotification,
    addErrorNotification,
  } = useToast()

  useEffect(() => {
    if (waiting && listing.campaignAssets.length >= 4) {
      if (
        assetLastGeneratedTime === null ||
        isAfter(
          parseISO(listing.campaignAssets[0].updated),
          parseISO(assetLastGeneratedTime)
        )
      ) {
        setWaiting(false)
        if (progressToastId.current) {
          addSuccessNotification({
            label: 'Assets generation successful',
            description: 'Assets generated successfully.',
            updateToastId: progressToastId.current!,
          })

          progressToastId.current = null
        }
      }
    }
  }, [
    addSuccessNotification,
    listing.campaignAssets,
    assetLastGeneratedTime,
    waiting,
  ])

  // While waiting, trigger a refetch of the campaign assets field every 5 seconds
  useEffect(() => {
    if (waiting) {
      const intervalId = setInterval(() => {
        if (waiting) {
          apollo.cache.modify({
            id: apollo.cache.identify({
              __typename: 'Listing',
              id: listing.id,
            }),
            fields: {
              campaignAssets: (value, { INVALIDATE }) => INVALIDATE,
            },
          })
        }
      }, 5000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [apollo.cache, listing.id, waiting])

  const generateCampaignAssets = async () => {
    progressToastId.current = addInfoNotification({
      label: 'Assets generation',
      description: 'Generating campaign assets...',
      progress: true,
    })

    if (listing.campaignAssets.length > 0) {
      setAssetLastGeneratedTime(listing.campaignAssets[0].updated)
    }
    setWaiting(true)
    await update()
  }

  const openFile = (type: string) => {
    const campaignAssetsZip = listing.campaignAssets.filter(({ name, url }) =>
      name.includes(type)
    )
    if (campaignAssetsZip && campaignAssetsZip.length > 0) {
      window.open(campaignAssetsZip[0].url, '_blank')
    } else {
      addErrorNotification({
        label: 'Campaign asset error',
        description: 'No campaign assets found. Please generate them.',
      })
    }
  }

  const isFileAvailable = (type: string, ext: string) => {
    const campaignAssets = listing.campaignAssets.filter(
      ({ name, url }) => url.includes(ext) && name.includes(type)
    )
    return campaignAssets && campaignAssets.length > 0
  }

  return (
    <Section>
      <SectionHeader hr h3="Campaign assets">
        {!waiting && isFileAvailable('all', 'zip') ? (
          <Button
            xsmall
            onClick={() => openFile('all')}
            label="Download all assets"
          />
        ) : null}
      </SectionHeader>
      <QrWrapper>
        <Flex>
          {!waiting &&
            campaignAssetTypes
              .filter(({ name, ext }) => isFileAvailable(name, ext))
              .map((campaignAsset, index) => {
                return (
                  <FlexItem
                    xs={{ size: 6, display: 'flex' }}
                    md={{ size: 3 }}
                    key={'campaign-asset-' + index}
                  >
                    <ThumbCardWrapper
                      href={
                        listing.campaignAssets.filter(({ name, url }) =>
                          name.includes(campaignAsset.name)
                        )[0].url
                      }
                      title={campaignAsset.name}
                      /* eslint-disable-next-line react/jsx-no-target-blank */
                      target="_blank"
                    >
                      <ThumbCard
                        image={campaignAsset.image}
                        imageFitCover
                        imageHeight="250px"
                        title={campaignAsset.title}
                        desc={
                          <Flex xs={{ wrap: 'nowrap' }}>
                            <FlexItem xs={{ grow: true }}>
                              {campaignAsset.desc}
                            </FlexItem>
                            <FlexItem>
                              <Icon svg={ArrowOutUpRight} />
                            </FlexItem>
                          </Flex>
                        }
                      />
                    </ThumbCardWrapper>
                  </FlexItem>
                )
              })}
        </Flex>
      </QrWrapper>
      {!waiting && (
        <Label>
          If you can't see any assets or they aren't looking right, you can try
          to&nbsp;
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => generateCampaignAssets()}>regenerate them</a>.
        </Label>
      )}
    </Section>
  )
}

ListingCampaignAssets.fragments = {
  Listing: gql`
    fragment ListingCampaignAssets_Listing on Listing {
      id
      url
      campaignAssets {
        name
        url
        updated
      }
    }
  `,
}

export const GENERATE_CAMPAIGN_ASSETS: TypedDocumentNode<
  GenerateCampaignAssetsMutation,
  GenerateCampaignAssetsMutationVariables
> = gql`
  mutation GenerateCampaignAssetsMutation($input: String!) {
    result: generateCampaignAssets(input: $input) {
      status
    }
  }
`
