import React, { Fragment } from 'react'
import { MatchProps } from '../../../util/match-props'

import { OffersView } from '../../offers/offers'

export interface ListingOffersProps extends MatchProps {
  listingId: string
}

export function ListingOffers({ listingId, match }: ListingOffersProps) {
  return (
    <Fragment>
      <OffersView listingId={listingId} match={match} />
    </Fragment>
  )
}
