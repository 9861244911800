import { gql, TypedDocumentNode, useMutation } from '@apollo/client'
import {
  formatDollarAmountForInput,
  FormattedNumberInput,
  getErrors,
} from '@propps/client'
import {
  Button,
  Input,
  Label,
  ListItem,
  ListWrapper,
  Section,
  SectionHeader,
  Select,
} from '@propps/ui'
import { useFormik } from 'formik'
import React from 'react'

import { useToast } from '../../../../components/toast'
import {
  UpdateListingPriceGuideMutation,
  UpdateListingPriceGuideMutationVariables,
} from './__generated__/UpdateListingPriceGuideMutation'

import { ListingPriceGuide_Listing } from './__generated__/ListingPriceGuide_Listing'

export function ListingPriceGuide({
  listing,
}: {
  listing: ListingPriceGuide_Listing
}) {
  const [update] = useMutation(UPDATE_LISTING_PRICE_GUIDE)

  const {
    addInfoNotification,
    addSuccessNotification,
    addErrorNotification,
  } = useToast()

  const formik = useFormik({
    initialValues: {
      priceIndication: listing.priceIndication,
    },
    onSubmit: async (values) => {
      const progressToastId = addInfoNotification({
        label: 'Price guide updating...',
        progress: true,
      })
      try {
        const fetchResult = await update({
          variables: {
            input: {
              id: listing.id,
              priceIndication: {
                type: values.priceIndication.type,
                max: values.priceIndication.max,
                min: values.priceIndication.min,
                message: values.priceIndication.message,
              },
            },
          },
        })
        if (fetchResult.errors) {
          addErrorNotification({
            label: 'Price guide update error',
            description: fetchResult.errors[0].message,
            updateToastId: progressToastId,
          })
          return
        }
        addSuccessNotification({
          label: 'Price guide updated',
          description: 'Price guide updated successfully',
          updateToastId: progressToastId,
        })
      } catch (e) {
        addErrorNotification({
          label: 'Price guide update error',
          description: e.message,
          updateToastId: progressToastId,
        })
        return
      }
    },
  })

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectValue = e.target.value.trim()
    formik.handleChange(e)
    switch (selectValue) {
      case 'min':
        formik.setFieldValue('priceIndication.max', null)
        break
      case 'custom':
        formik.setFieldValue('priceIndication.min', null)
        formik.setFieldValue('priceIndication.max', null)
        break
    }
  }

  return (
    <Section>
      <SectionHeader hr h3="Price guide" />
      <form onSubmit={formik.handleSubmit}>
        <ListWrapper>
          <ListItem key="price-item-type">
            <Label size="small" color="grey">
              Type
            </Label>
            <Select
              {...formik.getFieldProps(`priceIndication.type`)}
              errors={getErrors(formik, `priceIndication.type`)}
              onChange={onSelectChange}
            >
              <option value="min">Minimum</option>
              <option value="range">Range</option>
              <option value="custom">Custom</option>
            </Select>
          </ListItem>
          {(formik.values.priceIndication.type === 'min' ||
            formik.values.priceIndication.type === 'range') && (
            <ListItem key="price-item-min">
              <Label size="small" color="grey">
                From
              </Label>
              <FormattedNumberInput<React.ComponentProps<typeof Input>>
                component={Input}
                type="text"
                {...formik.getFieldProps('priceIndication.min')}
                onChange={(value: string) =>
                  formik.setFieldValue('priceIndication.min', +value)
                }
                format={formatDollarAmountForInput}
                errors={getErrors(formik, 'priceIndication.min')}
                inputMode="numeric"
                placeholder="$"
              />
            </ListItem>
          )}
          {formik.values.priceIndication.type === 'range' && (
            <ListItem key="price-item-range">
              <Label size="small" color="grey">
                To
              </Label>
              <FormattedNumberInput<React.ComponentProps<typeof Input>>
                component={Input}
                type="text"
                {...formik.getFieldProps('priceIndication.max')}
                onChange={(value: string) =>
                  formik.setFieldValue('priceIndication.max', +value)
                }
                format={formatDollarAmountForInput}
                errors={getErrors(formik, 'priceIndication.max')}
                inputMode="numeric"
                placeholder="$"
              />
            </ListItem>
          )}
          <ListItem key="price-item-custom">
            <Label size="small" color="grey">
              Message
            </Label>
            <Input
              type="textarea"
              placeholder="..."
              {...formik.getFieldProps(`priceIndication.message`)}
              errors={getErrors(formik, `priceIndication.message`)}
            />
          </ListItem>
        </ListWrapper>
        <Button type="submit">Save changes</Button>
      </form>
    </Section>
  )
}

ListingPriceGuide.fragments = {
  Listing: gql`
    fragment ListingPriceGuide_Listing on Listing {
      id
      priceIndication {
        type
        min
        max
        message
      }
    }
  `,
}

export const UPDATE_LISTING_PRICE_GUIDE: TypedDocumentNode<
  UpdateListingPriceGuideMutation,
  UpdateListingPriceGuideMutationVariables
> = gql`
  mutation UpdateListingPriceGuideMutation($input: UpdateListingInput!) {
    result: updateListing(input: $input) {
      listing {
        ...ListingPriceGuide_Listing
      }
    }
  }

  ${ListingPriceGuide.fragments.Listing}
`
