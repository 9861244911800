import React from 'react'
import { match as Match, Route, Switch } from 'react-router-dom'

import { Settings } from './settings'

export function SettingsRoot({ match }: { match: Match }) {
  return (
    <Switch>
      <Route path={`${match.path}`}>
        {({ match }) => <Settings match={match!} />}
      </Route>
    </Switch>
  )
}
