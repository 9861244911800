import {
  ConditionLines,
  formatPhoneNumber,
  FormattedPhoneNumberInput,
  GetOffer,
  useQuery,
  UserVerificationStatus,
} from '@propps/client'
import {
  Button,
  CircleCross,
  CircleInfo,
  CircleInfo2,
  CircleTick,
  color,
  FixedButtonWrapper,
  Flex,
  Icon,
  Input,
  Label,
  ListItem,
  ReadOnly,
  Section,
  SectionHeader,
  size,
  StackMain,
} from '@propps/ui'
import React from 'react'

import {
  findPrimaryBuyer,
  formatAddress,
  formatDate,
  formatDays,
  formatDollarAmount,
  getFinanceInstitutionDisplayName,
  getFinanceStatusMessage,
  getIdentityVerificationStatusMessage,
  isConditionalOffer,
} from '../../util/offer-utils'

export interface OfferViewProps {
  offerId: string
}

export function OfferView({ offerId }: OfferViewProps) {
  const { data: offer } = useQuery([GetOffer, { offerId }], {
    static: true,
  })

  if (!offer) {
    return null
  }

  const isConditional = isConditionalOffer(offer)
  const primaryBuyer = findPrimaryBuyer(offer)
  return offer ? (
    <StackMain variant="app">
      <h1>
        Offer for<span className="light">{formatAddress(offer.listing)}</span>
      </h1>
      <Section>
        <SectionHeader hr h3="Offer details" />
        <ListItem>
          <Label color="grey">Amount</Label>
          <ReadOnly>{formatDollarAmount(+offer.offer.amount)}</ReadOnly>
        </ListItem>
        <ListItem>
          <Label color="grey">Settlement</Label>
          <ReadOnly>{formatDays(offer.offer.settlement.value)}</ReadOnly>
        </ListItem>
        <ListItem>
          <Label color="grey">Date Submitted</Label>
          <ReadOnly>{formatDate(offer.created)}</ReadOnly>
        </ListItem>
      </Section>
      <Section>
        <SectionHeader
          hr
          h3={
            <Flex xs={{ alignItems: 'center' }}>
              <Icon
                svg={isConditional ? CircleInfo2 : CircleTick}
                fill={isConditional ? color.yellow : color.green}
                style={{ marginRight: `${size(1)}` }}
              />{' '}
              {isConditional ? 'Conditions' : 'Unconditional'}
            </Flex>
          }
        />
        {!!offer.offer.conditions.conditions.find(
          (condition) => condition.line === ConditionLines.SUBJECT_TO_FINANCE
        ) && (
          <ListItem>
            <Flex xs={{ alignItems: 'center' }}>
              <Icon svg={CircleInfo} style={{ marginRight: `${size(1)}` }} />
              Subject to finance
            </Flex>
          </ListItem>
        )}
        {!!offer.offer.conditions.conditions.find(
          (condition) =>
            condition.line === ConditionLines.SUBJECT_TO_BUILDING_INSPECTION
        ) && (
          <ListItem>
            <Flex xs={{ alignItems: 'center' }}>
              <Icon svg={CircleInfo} style={{ marginRight: `${size(1)}` }} />
              Subject to building inspection
            </Flex>
          </ListItem>
        )}
        {!!offer.offer.conditions.conditions.find(
          (condition) =>
            condition.line === ConditionLines.SUBJECT_TO_PEST_INSPECTION
        ) && (
          <ListItem>
            <Flex xs={{ alignItems: 'center' }}>
              <Icon svg={CircleInfo} style={{ marginRight: `${size(1)}` }} />
              Subject to pest inspection
            </Flex>
          </ListItem>
        )}
        {offer.offer.conditions.customCondition && (
          <ListItem>
            <Flex xs={{ alignItems: 'center' }}>
              <Icon svg={CircleInfo} style={{ marginRight: `${size(1)}` }} />
              Custom conditions
            </Flex>
          </ListItem>
        )}
      </Section>
      <Section>
        {primaryBuyer && (
          <>
            <SectionHeader
              hr
              h3={
                <Flex xs={{ alignItems: 'center' }}>
                  {primaryBuyer.verificationStatus ===
                    UserVerificationStatus.VERIFIED && (
                    <Icon
                      svg={CircleTick}
                      fill={color.green}
                      style={{ marginRight: `${size(1)}` }}
                    />
                  )}
                  {primaryBuyer.verificationStatus ===
                    UserVerificationStatus.FAILED && (
                    <Icon
                      svg={CircleInfo2}
                      fill={color.yellow}
                      style={{ marginRight: `${size(1)}` }}
                    />
                  )}
                  {primaryBuyer.verificationStatus ===
                    UserVerificationStatus.PENDING && (
                    <Icon
                      svg={CircleCross}
                      fill={color.red}
                      style={{ marginRight: `${size(1)}` }}
                    />
                  )}{' '}
                  Identity
                </Flex>
              }
            />

            <ListItem>
              <Label color="grey">Name</Label>
              <ReadOnly>{primaryBuyer.name}</ReadOnly>
            </ListItem>
            <ListItem>
              <Label color="grey">Phone</Label>
              <ReadOnly>
                {formatPhoneNumber(offer.buyers.signatories[0].phone, {
                  defaultCountry: 'AU',
                })}
              </ReadOnly>
            </ListItem>
            <ListItem>
              <Label color="grey">Email</Label>
              <ReadOnly>{offer.buyers.signatories[0].email}</ReadOnly>
            </ListItem>
          </>
        )}
        <ListItem>
          <Label color="grey">ID</Label>
          <ReadOnly>{getIdentityVerificationStatusMessage(offer)}</ReadOnly>
        </ListItem>
      </Section>
      <Section>
        <SectionHeader
          hr
          h3={
            <Flex xs={{ alignItems: 'center' }}>
              {['preapproved', 'not-applicable'].includes(
                offer.offer.finance.status
              ) ? (
                <Icon
                  svg={CircleTick}
                  fill={color.green}
                  style={{ marginRight: `${size(1)}` }}
                />
              ) : (
                <Icon
                  svg={CircleCross}
                  fill={color.red}
                  style={{ marginRight: `${size(1)}` }}
                />
              )}{' '}
              Finance
            </Flex>
          }
        />
        <ListItem>
          <Label color="grey">Status</Label>
          <ReadOnly>{getFinanceStatusMessage(offer)}</ReadOnly>
        </ListItem>
        {offer.offer.finance.institution ||
          (offer.offer.finance.institutionOther && (
            <ListItem>
              <Label color="grey">Institution</Label>
              <ReadOnly>{getFinanceInstitutionDisplayName(offer)}</ReadOnly>
            </ListItem>
          ))}
        {offer.offer.finance.dateObtained && (
          <ListItem>
            <Label color="grey">Date obtained</Label>
            <ReadOnly>{offer.offer.finance.dateObtained}</ReadOnly>
          </ListItem>
        )}
      </Section>
      <Section>
        <SectionHeader
          hr
          h3={
            <Flex xs={{ alignItems: 'center' }}>
              {offer.offer.legal.status === 'conveyancer' ? (
                <Icon
                  svg={CircleTick}
                  fill={color.green}
                  style={{ marginRight: `${size(1)}` }}
                />
              ) : (
                <Icon
                  svg={CircleCross}
                  fill={color.red}
                  style={{ marginRight: `${size(1)}` }}
                />
              )}{' '}
              Legal
            </Flex>
          }
        />
        <ListItem>
          <Label color="grey">Status</Label>
          <ReadOnly>
            {offer.offer.legal.status === 'conveyancer'
              ? 'Conveyancer engaged'
              : offer.offer.legal.status === ''
              ? 'Information not provided'
              : 'Conveyancer not engaged.'}
          </ReadOnly>
        </ListItem>
        {offer.offer.legal.conveyancerName && (
          <ListItem>
            <Label color="grey">Contact name</Label>
            <ReadOnly>{offer.offer.legal.conveyancerName}</ReadOnly>
          </ListItem>
        )}
        {offer.offer.legal.conveyancerPhone && (
          <ListItem>
            <Label color="grey">Phone</Label>
            <FormattedPhoneNumberInput<React.ComponentProps<typeof Input>>
              component={Input}
              type="text"
              readOnly
              defaultCountry="AU"
              value={offer.offer.legal.conveyancerPhone}
            />
          </ListItem>
        )}
      </Section>
    </StackMain>
  ) : (
    <FixedButtonWrapper>
      <Button cta pending />
    </FixedButtonWrapper>
  )
}
